import RuleEditor from './ruleEditor';
import PromotionFilter from './filters/promotionFilters';
import tinyAlert from './../core/tinyAlert';

import basketDiscountAction from './discounts/basketDiscountAction';
import basketBuyXGetYAction from './discounts/basketBuyXGetYAction';
import './promotions.scss';

var promotion;
let ruleEditor;
let promotionFilter;
let actionEditor;

export function initPromotions() {
    if (!$('#PromotionData').length) return;

    promotion = JSON.parse(document.getElementById('PromotionData').textContent);
    ruleEditor = RuleEditor('#Rules');
    promotionFilter = new PromotionFilter('#Filter');
    bindPromotion(promotion);
  
    $(document).on('click', '#SavePromotion', function (e) {
        e.preventDefault();
        if ($('body').hasClass('editing')) {
            tinyAlert("Please close all edits before saving", false);
            return;
        }
        savePromotion(); 
    });

    $(document).on('change', '.js-promotion-type', function (e) {
        var actionType = $(this).val();
        promotion.promotionType = actionType;

        var html;
        var container = $(document).find('.js-rule-action');
        if (actionType == 0) {
            $(".js-rule-filter-container").hide();
        } else {
            $(".js-rule-filter-container").show();
        }
        promotion.filters = [];
        promotionFilter.setValue(promotion.filters, actionType);

        switch (actionType) {
            case '1':
                actionEditor = new basketDiscountAction(container, 'Carriage Discount', 'The following actions will be applied if all rules pass.');
                actionEditor.setValue(null);
                break;
            case '0':
                actionEditor = new basketDiscountAction(container, 'Subtotal Discount', 'The following actions will be applied if all rules pass.');
                actionEditor.setValue(null);
                break;
            case '3':
                actionEditor = new basketDiscountAction(container, 'Product Discount', 'The following actions will be applied if all rules pass.');
                actionEditor.setValue(null);
                break;
            case '2':
                actionEditor = new basketBuyXGetYAction(container);
                actionEditor.setValue(null);
                break;
        }
        $(document).find('.js-rule-action').html(html);

    });
}   

function setActionEditor(promotion) {
    var container = $(document).find('.js-rule-action');
    switch (promotion.promotionType) {
        case 1:
            actionEditor = new basketDiscountAction(container, 'Carriage Discount', 'The following actions will be applied to the carriage if all rules pass.');
            actionEditor.setValue(promotion.discount);
            break;
        case 0:
            actionEditor = new basketDiscountAction(container, 'Subtotal Discount', 'The following actions will be applied to the basket subtotal if all rules pass.');
            actionEditor.setValue(promotion.discount);
            break;
        case 3:
            actionEditor = new basketDiscountAction(container, 'Product Discount', 'The following actions will be applied to individual basket items if all rules pass.');
            actionEditor.setValue(promotion.discount);
            break;
        case 2:
            actionEditor = new basketBuyXGetYAction(container);
            actionEditor.setValue(promotion.discount);
            break;
    }
}

function bindPromotion(promotion) {
    $('#PromotionName').val(promotion.name);
    $('#PromotionStatus').prop('checked', promotion.status == 0);
    $('#PromotionDescription').val(promotion.description);
    $('#Code').val(promotion.code);
    $('#InternalCode').val(promotion.internalCode);
    $('#PromotionType').val(promotion.promotionType);
    ruleEditor.bind(promotion.rules);
    promotionFilter.setValue(promotion.filters, promotion.promotionType);
    setActionEditor(promotion);

    if (promotion.promotionType == 0) {
        $(".js-rule-filter-container").hide();
    } else {
        $(".js-rule-filter-container").show();
    }

    console.log(promotion); 
}

function savePromotion() {
    var p = {
        promotionId: promotion.promotionId,
        name: $('#PromotionName').val(),
        description: $('#PromotionDescription').val(),
        prompt: $('#PromotionPrompt').val(),
        status: $('#PromotionStatus').is(':checked') ? 0 : 1,
        code: $('#Code').val(),
        internalCode: $('#InternalCode').val(),
        rules: ruleEditor.getData(),
        discount: actionEditor.getValue(),
        promotionType: $('#PromotionType').val(),
        filters: promotionFilter.getValue()
    }

    if ($('#PromotionName').val().length == 0) {
        alert('Promotion needs a name');
        return;
    }

    //if (!$('#Rules').find('.js-rule-editing').length) {
    //    alert('Please close all rule edits before saving');
    //    return;
    //}
    $.ajax
        ({
            type: "POST",
            url: '/promotions/save',
            async: false,
            data: JSON.stringify(p),
            success: function (data) {
                tinyAlert("Saved", true);
                promotion = JSON.parse(data);
                bindPromotion(promotion);
            }
        })
}
