import tinyAlert from './../core/tinyAlert';

export function initOrders() {
    $(document).on('click', '.js-lv', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        $(this).attr('href', '');
        var buttonText = $(this).html();
        var that = $(this);
        that.html('<i class="fa fa-spinner fa-spin"></i>');
        $.ajax({
            url: url,
            type: 'GET',
            processData: false,
            success: function (data) {
                tinyAlert(data.message, data.status == "ok");
                that.html(buttonText);

                if (data.status == "ok") {
                    that.closest('tr').find('.ordernumber').html(data.orderNumber);

                    if (data.orderStatusId == 5) {
                        that.closest('tr').find('.status').html('Completed');
                    }
                    if (data.orderStatusId == 4) {
                        that.closest('tr').find('.status').html('Sent to Lion');
                    }
                }
                that.attr('href', url);
            }
        });


    });

    $(document).on('click', '#SendAllToLV', function (e) {
        e.preventDefault();
        var that = $(this);
        var buttonText = that.html();
            that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Sending');

            $.ajax({
                url: '/orders/lvall',
                type: 'GET',
                processData: false,
                success: function (data) {
                    that.html(buttonText);
                    tinyAlert(data.Message, data.Status == "ok");
                }
            });

    });
}