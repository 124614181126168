import { uploadToMediaLibrary } from '../core/mediaLibrary';
import tinyAlert from './../core/tinyAlert';
import 'jquery-ui-bundle';
import showModal from '../core/lionModal';

export function initTagFiles() {
    $(document).ready(function () {
        initialiseEvents();
    });
}

function initialiseEvents() {

    // Edit Tag Files
    $(document).on('click', '.edittagfiles', function (e) {
        e.preventDefault();
        getTagFiles($(this).attr('data-id'));
    });

    // Remove Tag File
    $(document).on('click', '#TagFileList a', function (e) {
        var tagId = $(this).attr('data-id');
        var that = $(this).closest('tr');
        axios.delete(`/api/tagfiles/${tagId}`)
            .then((res) => {
                that.remove();
                tinyAlert("Removed", true);
            }).catch(function (error) {
                tinyAlert(error.response.data, false);
            });

    });

    // Upload Tag Files
    $(document).on('change', '#TagFile', function (e) {
        var tagId = $(this).attr('data-id');
        var path = 'tag/' + $(this).attr('data-id');
        var form = $(this).closest('.modal');
        console.log($(this));
        uploadToMediaLibrary($(this)[0], path, function (res) {
            $.each(res, function (i, data) {
                console.log(data);
                var formData = { tagId: tagId, fileUrl: data.filepath + '/' + data.filename, fileSize: data.fileSize, fileType: data.fileType, caption: data.filename };
                $.post('/api/tagfiles', formData, function (response) {
                    form.find('#TagFileList').append('<tr data-id="' + response.id + '"><td><a href="https://lionpfs.azureedge.net/live/' + data.filepath + '/' + data.filename + '" target="_blank">' + data.filepath + '/' + data.filename + '</a></td><td>' + data.fileType + '</td><td><input type="text" name="Name" value="' + data.filename + '" class="form-control tagFileName" /></td><td><a href="#" data-id="' + response.id + '">Remove</a></td></tr>');
                });
            }, null, false);
        });
    });

    // Update Tag File
    $(document).on('blur', '.tagFileName', function (e) {
        var that = $(this).closest('tr');
        var tagId = that.attr('data-id');
        var formData = { id: tagId, caption: $(this).val() };
        $.post('/api/tagfiles/' + tagId, formData, function (response) {
            tinyAlert("Updated", true);
        });
    });
}


function getTagFiles(id) {
    $.get(`/api/tagfiles/${id}`, function (res) {
        let html = `<table class="table table-striped" id="TagFileList">
                            <tr><th>Url</th><th>Type</th><th>Name/Caption</th><th></th></tr>
                            ${res.map((o) => `<tr data-id="${o.id}"><td><a href="https://lionpfs.azureedge.net/live/${o.fileUrl}" target="_blank">${o.fileUrl}</a></td><td>${o.fileType}</td><td><input type="text" name="Name" value="${o.caption}" class="form-control tagFileName" /></td><td><a href="#" data-id="${o.id}">Remove</a></td></tr>`).join('')} 
                        </table>`

        let footer = `<label class="input col-sm-12">
                            <label for="file" class="input input-file">
                                <div class="button">
                                    <input type="file" class="file-preview" multiple name="TagFile" id="TagFile" data-id="${id}">Upload Files
                                </div>
                                <span></span>
                            </label>
                        </label>`

        showModal('TagFilesEdit', '80%', 'Edit Tag Files', '', html, footer);
    });
}

