import { uploadToMediaLibrary } from '../core/mediaLibrary';
import showModal from '../core/lionModal';
import 'bootstrap';
import "sortablejs";
import 'jstree';
import tinyAlert from './../core/tinyAlert';
import 'jquery-ui-bundle';

function isFileImage(file) {
    return file && file['type'].split('/')[0] === 'image';
}

export function initTagging() {


    $(document).on('change', '#TagImageFile', function (e) {
        var path = 'tag/' + $(this).attr('data-id');
        var modalWindow = $(this).closest('.modal-body');
        var url = URL.createObjectURL(event.target.files[0]);
        modalWindow.find('#TagImageImage').html('<img src="' + url + '" class="img-responsive"/>');
        uploadToMediaLibrary($(this)[0], path, function (res) {
            $.each(res, function (i, data) {
                //modalWindow.find('#TagImageImage').html('<img src="' + data.remoteUrlSmall + '" />');
                modalWindow.find('#ImageUrl').val(data.url);
            });
        });
    });

    //$(document).on('change', '#TagImageFile', function (e) {
    //    var path = 'tag/' + $(this).attr('data-id');
    //    var modalWindow = $(this).closest('.modal-body');
    //    var url = URL.createObjectURL(event.target.files[0]);
    //    modalWindow.find('#TagImageImage').html('<img src="' + url + '" class="img-responsive"/>');
    //    //uploadToMediaLibrary($(this)[0], path, function (res) {
    //    //    $.each(res, function (i, data) {
    //    //        modalWindow.find('#TagImageImage').html('<img src="' + data.remoteUrlSmall + '" />');
    //    //        modalWindow.find('#TagImageId').val(data.id);
    //    //    });
    //    //});
    //});

  



    $(document).on('click', '#ShowTagWindow', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        $('#TagImageFile').attr('data-id', id);
        $('#FacetId').val(id);
        $('#QuickAddTag').modal('show');
    });
    $(document).on('click', '#ShowFacetWindow', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        $.get('/tagging/facet/' + id, function (res) {
            $('#QuickEditFacet .modal-body').html(res);
            $('#QuickEditFacet').modal('show');
        });
    });

    $(document).on('click', '.edittag', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        $.get('/tagging/tag/' + id, function (res) {
            $('#QuickEditTag .modal-body').html(res);
            $('#QuickEditTag').modal('show');
        });
     
    });



    $(document).on('click', '#DeleteFacet', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var that = $(this);
        var buttonText = that.html();
        if (confirm('Are you sure?')) {
            that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Deleting');

            $.ajax({
                url: '/tagging/deletefacet?id=' + id,
                type: 'GET',
                processData: false,
                success: function (data) {
                    that.html(buttonText);
                    $('#' + id).remove();
                    tinyAlert(data.Message, data.Status == "ok");
                    $('.listing').html("");
                }
            });
        }

    });

    $(document).on('click', '.delete-tag', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var that = $(this);
        var buttonText = that.html();
        if (confirm('Are you sure?')) {
            that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Deleting');

            $.ajax({
                url: '/tagging/deletetag?id=' + id,
                type: 'GET',
                processData: false,
                success: function (data) {
                    that.closest('li').remove();
                    tinyAlert(data.message, data.status == "ok");
                }
            });
        }

    });

    $(document).on('click', '#Reindex', function (e) {
        e.preventDefault();
        var that = $(this);
        var buttonText = that.html();
        if (confirm('Reindexing takes around 30 seconds. Are you sure you want to reindex?')) {
            that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Reindexing');

            $.ajax({
                url: '/tagging/reindex',
                type: 'GET',
                processData: false,
                success: function (data) {
                    that.html(buttonText);
                    tinyAlert(data.Message, data.Status == "ok");
                    $('#LastIndexed').html(data.Item.Value);
                    $('#TotalDocuments').html(data.Count);
                }
            });
        }

    });

    

    $(document).on('submit', '#QuickAddTag form', function (e) {
        e.preventDefault();
        addTag();
        $('#QuickAddTag .close').trigger('click');
    });
    $(document).on('submit', '#QuickEditTag form', function (e) {
        e.preventDefault();
        saveTag();
        $('#QuickEditTag .close').trigger('click');
    });
    $(document).on('submit', '#QuickEditFacet form', function (e) {
        e.preventDefault();
        saveFacet();
        $('#QuickEditFacet .close').trigger('click');
    });
    $(document).on('submit', '#QuickAddFacet form', function (e) {
        e.preventDefault();
        addFacet();
        $('#QuickAddFacet .close').trigger('click');
    });


    $(function () {


        $('#tree')
            .on('changed.jstree', function (e, data) {
                var i, j, r = [], n, t, p = [];
                for (i = 0, j = data.selected.length; i < j; i++) {
                    r.push(data.instance.get_node(data.selected[i]).text);
                    n = data.instance.get_node(data.selected[i]).id;
                    t = data.instance.get_node(data.selected[i]).text;
                    p = data.instance.get_path(data.selected[i]);
                }

                if (data.selected.length > 0) {
                    $.ajax({
                        url: "/Tagging/GetTags",
                        data: { id: n },
                        success: function (data) {
                            $('.listing').hide().html(data).fadeIn();
                            $("#sortable").sortable({
                                stop: function () {
                                    var children = [];
                                    $("#sortable li").each(function (idx, val) {
                                        $(this).find('.taghidden').val(idx);
                                        children.push($(this).attr('data-id'));
                                    });
                                    reorderTags(n, children);
                                }
                            });
                        },
                        cache: false
                    });
                }

            })
            .on('move_node.jstree', function (e, data) {
                var id = data.node.id;
                var children = data.instance.get_node(data.node.parent).children;

                reorderFacets(id, children);
            })
            .jstree({
                'core': {
                    'check_callback': function (op, node, par, pos, more) {
                        if (more && more.dnd) {
                            return more.pos !== "i" && par.id == node.parent;

                        }
                        return true;
                    },
                    'data': {
                        'url': '/Tagging/GetChildren',
                        'data': function (node) {
                            return { 'id': node.id };
                        }

                    }
                },
                "plugins": ["dnd", "crrm"],
                'contextmenu': {
                    'items': function customMenu($node) {
                        return {
                            createItem: {
                                "label": "Create Facet",
                                "action": function () {
                                    var selectedId = $('#tree').jstree('get_selected');
                                    CreateFacet(selectedId);
                                }
                            },
                            deleteItem: {
                                "label": "Delete Facet",
                                "action": function () {
                                    DeleteFacet($node.id);
                                }
                            }
                        };
                    }
                }
            });

    });
}

function createNode(treeId, id, name) {
    var $root = $('#' + treeId);
    var parent = $root.jstree('get_selected');
    var newNode = { state: "open", text: name, id: id };
    $root.jstree(true).create_node(parent, newNode);
    $root.jstree(true).open_node(parent);
}


function getTags(id, callback) {
        $.ajax({
            url: '/tagging/gettags?id=' + id,
            type: 'GET',
            processData: false,
            success: function (data) {
                $('.listing').html(data);
                $("#sortable").sortable({
                    stop: function () {
                        var children = [];
                        $("#sortable li").each(function (idx, val) {
                            $(this).find('.taghidden').val(idx);
                            children.push($(this).attr('data-id'));
                        });
                        reorderTags(id, children);
                    }
                });
    
                callback(data);
            }
        });
}



function addTag() {
    if ($('#QuickAddTag form').valid()) {

        var data = $('#QuickAddTag form').serialize();
        var that = $('#QuickAddTag form').find('button.btn-success');
        var data = $('#QuickAddTag form').serialize();
        console.log(data);
        var buttonText = that.html();
        that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Saving');
        $.ajax({
            url: '/tagging/addtag',
            type: 'POST',
            data: data,
            processData: false,
            success: function (data) {
                that.html(buttonText);

                getTags(data.Item.FacetId, function (res) {

                    $('#QuickAddTag .close').trigger('click');
                });
            }
        });
    }
}

function saveTag() {
    if ($('#QuickAddTag form').valid()) {
     
           

        var data = $('#QuickEditTag form').serialize();
        var that = $('#QuickEditTag form').find('button.btn-success');
        var data = $('#QuickEditTag form').serialize();
        console.log(data);
        var buttonText = that.html();
        that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Saving');
        $.ajax({
            url: '/tagging/savetag',
            type: 'POST',
            data: data,
            processData: false,
            success: function (data) {
                that.html(buttonText);

                tinyAlert(data.Message, data.Status == "ok");
                getTags(data.Item.FacetId, function (res) {
                    $('#QuickEditTag .close').trigger('click');
                });
            }
        });
    }
}

function addFacet() {
    if ($('#QuickAddFacet form').valid()) {

        var data = $('#QuickAddFacet form').serialize();
        var that = $('#QuickAddFacet form').find('button.btn-success');
        var data = $('#QuickAddFacet form').serialize();
        console.log(data);
        var buttonText = that.html();
        that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Saving');
        $.ajax({
            url: '/tagging/addfacet',
            type: 'POST',
            data: data,
            processData: false,
            success: function (data) {
                that.html(buttonText);

               // createNode('tree', data.Item.Id, data.Item.Name);
                getTags(data.Item.Id, function (res) {
                    $('#QuickAddFacet .close').trigger('click');
                });
            }
        });
    }
}

function saveFacet() {
    if ($('#QuickEditFacet form').valid()) {

        var that = $('#QuickEditFacet form').find('button.btn-success');
        var data = $('#QuickEditFacet form').serialize();
        console.log(data);
        var buttonText = that.html();
        that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Saving');
        $.ajax({
            url: '/tagging/savefacet',
            type: 'POST',
            data: data,
            processData: false,
            success: function (data) {
                that.html(buttonText);
                tinyAlert(data.Message, data.Status == "ok");
                getTags(data.Item.Id, function (res) {
                    $('.listing').html(res);
                    $('#QuickEditFacet .close').trigger('click');
                });
            }
        });
    }
}

function reorderFacets(id, children) {
    $.ajax({
        url: "/Tagging/ReorderFacet",
        data: { id: id, children: children.join(',') },
        success: function (data) {
            tinyAlert(data.message, data.status == "ok");
        },
        cache: false
    });
}
function reorderTags(id, children) {
    $.ajax({
        url: "/Tagging/ReorderTags",
        data: { id: id, children: children.join(',') },
        success: function (data) {
            tinyAlert(data.message, data.status == "ok");
        },
        cache: false
    });
}

