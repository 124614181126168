import 'jquery-ui-bundle';
import 'bootstrap';

export default function showModal(id, width, title, header, body, footer) {
    if ($(document).find('#' + id).length) {
        var modal = $(document).find('#' + id);
        modal.find('.modal-header').html(modalHeader(title, header));
        modal.find('.modal-body').html(body);
        modal.find('.modal-footer').html(footer);
    } else {
        $(document).find('body').append(modalTemplate(id, width, title, header, body, footer));
    }
    $('#' + id).modal('show');
}

const modalTemplate = (id, width, title, header, body, footer) => `
    <div class="modal fade" id="${id}" tabindex="-1" role="dialog" aria-labelledby="remoteModalLabel" aria-hidden="true">
        <div class="modal-dialog" style="width:${width}">
            <div class="modal-content smart-form">
                <div class="modal-header">
                    ${modalHeader(title, header)}
                </div>
                <div class="modal-body">
                    ${body}
                </div>
                 <div class="modal-footer">
                    ${footer}
                </div>
            </div>
        </div>
    </div>
`;


const modalHeader = (title, body) => `
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        ×
                    </button>
                    <h4 class="modal-title">${title}</h4>
                    <div>${body}</div>
`;