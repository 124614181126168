

export function searchTable() {
    $('.searchTable').each(function (i, v) {
        applyFunctions('#' + $(this).attr('id'));
    });
}

function applyFunctions(formSelector) {
    console.log(formSelector)
    var form = $(formSelector);
    console.log(form)

    $(document).on('click', formSelector + ' button[type=submit]', function (e) {
        e.preventDefault();
        $(this).closest('.searchTable').find('.js-page').val(1);
        DoSearch(form);
    });

    $(document).on('click', formSelector + ' input[type=checkbox]', function (e) {
        $(this).closest('.searchTable').find('.js-page').val(1);
        DoSearch(form);
    });

    $(document).on('change', formSelector + ' select', function (e) {
        $(this).closest('.searchTable').find('.js-page').val(1);
        DoSearch(form);
    });

    $(document).on('click', formSelector + ' .clickable-table tr', function (e) {
        window.location.href = $(this).attr('data-url');
    });



    $(document).on('keydown', formSelector + ' .search', function (e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            $(this).closest('.searchTable').find('.js-page').val(1);
            DoSearch(form);
        }
    });
    $(document).on('click', formSelector + ' .pagination li a', function (e) {
        e.preventDefault();
        var pageId = parseInt($(this).attr('data-page'));
        console.log(pageId);
        $(this).closest('.searchTable').find('.js-page').val(pageId);
       DoSearch(form);
    });

    $(document).on('click', formSelector + ' .reorder', function (e) {
        e.preventDefault();
        var currentSortField = $(this).closest('.searchTable').find('.js-sortfield').val();
        var newSortField = $(this).attr('data-sortfield');
        var reverse = $(this).closest('.searchTable').find('.js-reverse').val();

        if (newSortField == currentSortField) {
            $(this).closest('.searchTable').find('.js-reverse').val((reverse == "True" ? "False" : "True"));
        } else {
            $(this).closest('.searchTable').find('.js-sortfield').val(newSortField);
        }

        DoSearch(form);
    });
}

function DoSearch(form) {
    var url = form.attr('action');
    var method = form.attr('method');
    var data = form.serialize();
    $.ajax({
        url: url,
        data: data,
        method:method,
        success: function (res) {
            form.find('.js-results').hide().html(res).fadeIn();
            window.scrollTo(0, 0);
        },
        cache: false
    });
}