

export function renderActionType(action, actionTypes) {

    if (action == undefined ||  action.type == undefined) {
        return ``;
    }
    var ruleDef = actionTypes.find(function (f) {
        return f.type == action.type;
    });
    if (ruleDef == undefined) {
        return ``;
    }
    var html = `
                <select class="form-control valid" aria-invalid="false">
                   ${ruleDef.actionTypes.map(i => `<option value='${i.id}' ${action.actionType == i.id ? 'selected': ''}>${i.name}</option>`).join('')}

                </select>
            `;
    return html;
}

export function percentageInput(value) {
    return `<div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Percentage" aria-label="Username" aria-describedby="basic-addon1" value="${value}">
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
            </div>`;
}

export function currencyInput(value) {
    return `<div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">£</span>
              </div>
              <input type="text" class="form-control" placeholder="Amount" aria-label="Username" aria-describedby="basic-addon1" value="${value}">
            </div>`;
}

export function createPercentageInput(action) {
    return `<div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Percentage" aria-label="Username" aria-describedby="basic-addon1" value="${action != undefined ? action.value : ''}">
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
            </div>`;
}

export function createCurrencyInput(action) {
    return `<div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">£</span>
              </div>
              <input type="text" class="form-control" placeholder="Amount" aria-label="Username" aria-describedby="basic-addon1" value="${action != undefined ? action.value : ''}">
            </div>`;
}

export function createProductTypeInput(action) {
    return `
        <select class="selectize" multiple>
            <option value="1" ${action != undefined && action.includes("1") ? 'selected' : ''}>Standard</option>
            <option value="2" ${action != undefined && action.includes("2") ? 'selected' : ''}>Moulding</option>
            <option value="3" ${action != undefined && action.includes("3") ? 'selected' : ''}>Mountboard</option>
            <option value="4" ${action != undefined && action.includes("4") ? 'selected' : ''}>Fineprint</option>
   <option value="5" ${action != undefined && action.includes("4") ? 'selected' : ''}>Chevron</option>
   <option value="6" ${action != undefined && action.includes("4") ? 'selected' : ''}>StyreneSheet</option>
   <option value="7" ${action != undefined && action.includes("4") ? 'selected' : ''}>Chop</option>
        </select>
    `;
}


export function createProductTypeText(action) {
    switch (action) {
        case "1":
            return "Standard";
        case "2":
            return "Moulding";
        case "3":
            return "Mountboard";
        case "4":
            return "Fineprint";
        case "5":
            return "Chevron";
        case "6":
            return "StyreneSheet";
        case "7":
            return "Chop";
    }
    return '';
}