import 'bootstrap';
import { uploadToMediaLibrary } from '../core/mediaLibrary';
import tinyAlert from './../core/tinyAlert';

export function initMedia() {
    $(document).on('click', '#QuickAddFileWindow button[type=submit]', function (e) {
        e.preventDefault();
        addMediaFile('#QuickAddFileWindow');
    });
    $(document).on('click', '#QuickEditFileWindow button[type=submit]', function (e) {
        e.preventDefault();
        addMediaFile('#QuickEditFileWindow');
    });
    $(document).on('click', '.edit-file', function (e) {
        e.preventDefault();
        editMediaFile($(this).attr('data-id'));
    });

    $(document).on('click', '.delete-file', function (e) {
        e.preventDefault();
        deleteProductFile($(this).attr('data-id'));
    });

    $(document).on('click', '#QuickAddProductFileWindow button[type=submit]', function (e) {
        e.preventDefault();
        addMediaProductFile('#QuickAddProductFileWindow');
    });
    $(document).on('click', '#QuickAddProductFileWindow1 button[type=submit]', function (e) {
        e.preventDefault();
        addMediaProductFile('#QuickAddProductFileWindow1');
    });
}

function editMediaFile(id) {
    $.ajax({
        url: '/media/' + id,
        cache: false,
        contentType: false,
        processData: false,
        type: 'GET',
        success: function (data) {
            $('#QuickEditFileInner').html(data);
            $('#QuickEditFileWindow').modal('show');
        }
    });

}

function deleteProductFile(id) {
    if (confirm('are you sure you want to delete this file?'))
    {
        $.ajax({
            url: '/media/delete/' + id,
            cache: false,
            processData: false,
            type: 'POST',
            success: function (data) {
                window.location.href = '/media';
            }
        });
    }
}

function addMediaFile(modalId) {
    // if ($('#QuickAddFile').valid()) {
    var data = new FormData();
    if ($(modalId + ' #Thumbnail')[0] != undefined)
    data.append('Thumbnail', $(modalId + ' #Thumbnail')[0].files[0]);

    if ($(modalId + ' #File')[0] != undefined)
    data.append('File', $(modalId + ' #File')[0].files[0]);

    data.append('Description', $(modalId + ' #Description').val());
    data.append('ProductCodes', $(modalId + ' #ProductCodes').val());
    data.append('Id', $(modalId + ' #FileId').val());

    $.ajax({
        url: '/media/add',
        data: data,
        cache: false,
        contentType: false,
        processData: false,
        type: 'POST',
        success: function (data) {
            tinyAlert("File Saved", true);
            $(modalId + ' .close').trigger('click');
            window.location.href = '/media';
        }
    });
    // }
}

function addMediaProductFile(modalId) {
    // if ($('#QuickAddFile').valid()) {
    var data = new FormData();
    if ($(modalId + ' #Thumbnail')[0] != undefined)
        data.append('Thumbnail', $(modalId + ' #Thumbnail')[0].files[0]);

    if ($(modalId + ' #File')[0] != undefined)
        data.append('File', $(modalId + ' #File')[0].files[0]);

    data.append('Description', $(modalId + ' #Description').val());
    data.append('ProductCodes', $(modalId + ' #ProductCodes').val());
    data.append('Id', $(modalId + ' #FileId').val());

    $.ajax({
        url: '/media/add',
        data: data,
        cache: false,
        contentType: false,
        processData: false,
        type: 'POST',
        success: function (res) {
            tinyAlert("File Saved", true);
            $(modalId + ' .close').trigger('click');
            $.ajax({
                url: "/media/get/" + res.id,
                success: function (data) {
                    var html = `<tr data-id="${data.Id}">
                            <td>${data.Filename}</td>
                            <td>${data.FileType}</td>
                            <td>${data.FileSize}</td>
                            <td>${data.Created}</td>
                            <td>${data.Description}</td>
                            <td style="text-align:center;width:60px;">
                                    <img src="${data.ThumbnailUrl}" style="max-width:40px;">
                            </td>
                            <td>
                                <div class="btn-group">
                                    <button class="btn btn-default dropdown-toggle" data-toggle="dropdown">
                                        ...
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-right">
                                        <li>
                                            <a href="#" class="delete-product-file" data-id="${data.Id}">Delete</a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>`;
                    $('#product-files tbody').append(html);
                    $(".searchfiles").val('');
                }
            });
        }
    });
    // }
}

function addFolder() {
    if ($('#AddFolderForm').valid()) {
        var id = $('#AddFolderForm').find('#ParentFolderId').val();
        var data = $('#AddFolderForm').serialize();
        var that = $('#AddFolderForm').find('button.btn-success');
        console.log(data);
        var buttonText = that.html();
        that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Saving');
        $.ajax({
            url: '/infostore/createfolder',
            type: 'POST',
            data: data,
            processData: false,
            success: function (data) {
                that.html(buttonText);
                getFolders(id);
                $('#AddFolderModal .close').trigger('click');
            }
        });
    }
}

function saveFolder() {
    if ($('#EditFolderForm').valid()) {
        var id = $('#EditFolderForm').find('#FolderId').val();
        var data = $('#EditFolderForm').serialize();
        var that = $('#EditFolderForm').find('button.btn-success');
        console.log(data);
        var buttonText = that.html();
        that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Saving');
        $.ajax({
            url: '/infostore/savefolder',
            type: 'POST',
            data: data,
            processData: false,
            success: function (data) {
                that.html(buttonText);
                getAll(id);
                $('#EditFolderModal .close').trigger('click');
            }
        });
    }
}

function deleteFolder(id) {
    if (confirm('are you sure you want to delete this folder and all the files within?')) {
        $.ajax({
            url: "/infostore/deletefolder",
            data: { id: id },
            success: function (data) {
                if (data.Status == 'Ok') {
                    $('#' + id).remove();
                    getAll(data.Id);
                    //      tinyAlert(data.Message, data.Status == "Ok");
                } else {
                    alert(data.Message);
                    // tinyAlert(data.Message, data.Status == "Ok");
                }
            },
            cache: false
        });
    }
}

function getFolders(id, callback) {
    $.ajax({
        url: "/infostore/folders",
        data: { id: id },
        success: function (data) {
            if (callback != 'undefined' && typeof callback == "function")
                callback(data);
            else
                $('#folders').hide().html(data).fadeIn();
        },
        cache: false
    });
}

function getFiles(id, callback) {
    $.ajax({
        url: "/infostore/files",
        data: { id: id },
        success: function (data) {
            if (callback != 'undefined' && typeof callback == "function")
                callback(data);
            else
                $('#files').hide().html(data).fadeIn();


        },
        cache: false
    });
}

function getAll(id) {
    getFolder(id, function (folder) {
        getFolders(id, function (folders) {
            getFiles(id, function (files) {
                $('.listing').hide().html(folder).fadeIn();
                $('#folders').hide().html(folders).fadeIn();
                $('#files').hide().html(files).fadeIn();
            });
        });
    });
}

function getFolder(id, callback) {
    $.ajax({
        url: "/infostore/folder",
        data: { id: id },
        success: function (data) {
            if (callback != 'undefined' && typeof callback == "function")
                callback(data);
            else
                $('.listing').hide().html(data).fadeIn();
        },
        cache: false
    });
}

function getFile(id) {
    $.ajax({
        url: "/infostore/file",
        data: { id: id },
        success: function (data) {
            $('#Modal #ModalBody').html(data);
            $('#Modal #ModalTitle').html('Edit File');
            $('#Modal').modal('show');
        },
        cache: false
    });

}

function saveFile() {
    var data = new FormData();
    data.append('ThumbnailFile', $('#SaveFileForm #ThumbnailFile')[0].files[0]);
    data.append('FileName', $('#FileName').val());
    data.append('FileDescription', $('#FileDescription').val());
    data.append('FileId', $('#FileId').val());

    var products = [];
    $('#Parts tr').each(function (i, v) {
        products.push($(this).attr('data-id'));
    });
    data.append('Products', products.join(','));


    $.ajax({
        url: '/infostore/savefile',
        data: data,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        type: 'POST',
        success: function (data) {
            showAlert("File Saved", true);
            $('#Modal .close').trigger('click');
        }
    });
}

function deleteFile(id, parentId) {
    if (confirm('are you sure you want to delete this file?')) {
        $.ajax({
            url: "/infostore/deletefile",
            data: { id: id },
            success: function (data) {
                if (data.Status == "Ok") {
                    $('#' + id).remove();
                    getAll(parentId);
                } else {
                    alert(data.Message);
                    //  tinyAlert(data.Message, data.Status == "Ok");
                }
            },
            cache: false
        });
    }
}

function showEditFolderWindow() {
    $('#EditFolderModal').find('#FolderId').val($('#EditFolderId').val());
    $('#EditFolderModal').find('#FolderName').val($('#EditFolderName').val());
    $('#EditFolderModal').find('#FolderDescription').val($('#EditFolderDescription').val());

    var bannerFileUrl = $('#EditFolderBannerFileUrl').val();
    var bannerFileId = $('#EditFolderBannerFileId').val();
    var thumbnailFileUrl = $('#EditFolderThumbnailFileUrl').val();
    var thumbnailFileId = $('#EditFolderThumbnailFileId').val();

    if (bannerFileUrl.length > 1) {
        $('#EditFolderModal').find('#FolderBannerFileUrl').attr('src', bannerFileUrl);
        $('#FolderBannerFileUrl').show();
    } else {
        $('#FolderBannerFileUrl').hide();
    }

    if (thumbnailFileUrl.length > 1) {
        $('#EditFolderModal').find('#FolderThumbnailFileUrl').attr('src', thumbnailFileUrl);
        $('#FolderThumbnailFileUrl').show();
    } else {
        $('#FolderThumbnailFileUrl').hide();
    }

    $('#EditFolderModal').find('#FolderThumbnailFileId').html(thumbnailFileId);
    $('#EditFolderModal').find('#FolderBannerFileId').html(bannerFileId);
    $('#EditFolderModal').modal('show');
}

function showAddFolderWindow(id, name) {
    var modalWindow = $('#AddFolderModal');
    modalWindow.find('#ParentFolderId').val(id);
    modalWindow.find('.modal-title').html('Add Folder to ' + name);
    modalWindow.find('#AddFolderName').val('');
    modalWindow.find('#AddFolderDescription').val('');
    modalWindow.find('#FolderBannerFileId').val('');
    modalWindow.find('#FolderThumbnailFileId').val('');
    modalWindow.find('#FolderBannerFileUrl').hide();
    modalWindow.find('#FolderThumbnailFileUrl').hide();
    $('#AddFolderModal').modal('show');
}

function uploadFile() {
    var folderId = $('#UploadFile').attr('data-folder');
    var data = new FormData();
    data.append('File', $('#UploadFile')[0].files[0]);
    data.append('FolderId', folderId);

    $.ajax({
        url: '/infostore/uploadfile',
        data: data,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        type: 'POST',
        success: function (data) {
            getFiles(folderId);
        }
    });
}

export function initMediaLibrary() {
    $(document).on('click', '#AddFolder', function (e) {
        e.preventDefault();
        addFolder();
    });

    $(document).on('click', '#SaveFolder', function (e) {
        e.preventDefault();
        saveFolder();
    });

    $(document).on('click', '#EditFolder', function (e) {
        e.preventDefault();
        showEditFolderWindow();
    });
    $(document).on('click', '#DeleteFolder', function (e) {
        e.preventDefault();
        deleteFolder($(this).attr('data-id'));
    });
    $(document).on('click', '#AddFolderBtn', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var name = $(this).attr('data-name');
        showAddFolderWindow(id, name);
    });

    $(document).on('change', '#AddFolderBannerFile', function (e) {
        var modalWindow = $(this).closest('.modal');
        var path = 'folder/' + modalWindow.find('#ParentFolderId').val();
        uploadToMediaLibrary($(this)[0], path, function (res) {
            $.each(res, function (i, data) {
                modalWindow.find('#FolderBannerFileUrl').attr('src', data.remoteUrlSmall);
                modalWindow.find('#FolderBannerFileId').val(data.id);
                modalWindow.find('#FolderBannerFileUrl').show();
            });
        });
    });

    $(document).on('change', '#AddFolderThumbnailFile', function (e) {
        var modalWindow = $(this).closest('.modal');
        var path = 'folder/' + modalWindow.find('#ParentFolderId').val();
        uploadToMediaLibrary($(this)[0], path, function (res) {
            $.each(res, function (i, data) {
                modalWindow.find('#FolderThumbnailFileUrl').attr('src', data.remoteUrlSmall);
                modalWindow.find('#FolderThumbnailFileId').val(data.id);
                modalWindow.find('#FolderThumbnailFileUrl').show();
            });
        });
    });



    $(document).on('click', '#folders table a', function (e) {
        e.preventDefault();
        getAll($(this).attr('data-id'));
    });

    $(document).on('click', '#ParentLink', function (e) {
        e.preventDefault();
        getAll($(this).attr('data-id'));
    });
    $(document).on('change', '#UploadFile', function (e) {
        e.preventDefault();
        uploadFile();
    });

    $(document).on('click', '.filelink', function (e) {
        e.preventDefault();
        getFile($(this).attr('data-id'));
    });

    $(document).on('click', '.save-file', function (e) {
        e.preventDefault();
        saveFile();
    });
    $(document).on('click', '#Parts a', function (e) {
        e.preventDefault();
        $(this).closest('tr').remove();
    });
    $(document).on('click', '#AddPart', function (e) {
        e.preventDefault();
        var html = '<tr data-id="' + $('#PartCode').val() + '"><td>' + $('#PartCode').val() + '</td><td></td><td><a href="#">Remove</a></td></tr>';
        $('#Parts').find('tbody').append(html);
    });

    $(document).on('click', '.deletefile', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var parentId = $(this).attr('data-parent');
        deleteFile(id, parentId);
    });

    $(function () {
        $('#mediatree')
            .on('changed.jstree', function (e, data) {
                var i, j, r = [], n, t, p = [];

                for (i = 0, j = data.selected.length; i < j; i++) {
                    r.push(data.instance.get_node(data.selected[i]).text);
                    n = data.instance.get_node(data.selected[i]).id;
                    t = data.instance.get_node(data.selected[i]).text;
                    p = data.instance.get_path(data.selected[i]);
                }
                var typ = $('#' + n).data('type');

                if (data.selected.length > 0) {
                    if (typ != 'file') {
                        getAll(n);
                    } else {
                        getFile(n);
                    }

                }

            })
            .jstree({
                'core': {
                    'check_callback': function (op, node, par, pos, more) {

                        if ((op === "move_node" || op === "copy_node") && node.type && node.type == "root") {

                            return false;
                        }
                        if ((op === "move_node" || op === "copy_node") && more && more.core) {
                            var nodeParent = $('#' + par.id);
                            var nodeCurrent = $('#' + node.id);
                            if (nodeParent.data('type') != 'cat') {
                                alert('Cannot drop onto a file');
                                return false;
                            } else {
                                $.ajax({
                                    url: "/Infostore/changecategory?fileid=" + nodeCurrent.data('fileid') + "&categoryId=" + par.id,
                                    success: function () {
                                        return true;
                                    },
                                    cache: false
                                });
                                //return true;
                            }
                        }
                        return true;
                    },
                    'data': {
                        'url': '/Infostore/GetChildren',
                        'data': function (node) {
                            return { 'id': node.id };
                        }

                    }
                },
                "plugins": ["contextmenu", "dnd", "crrm"],
                'contextmenu': {
                    'items': function customMenu($node) {
                        var typ = $('#' + $node.id).data('type');
                        if (typ != 'file') {
                            return {
                                createItem: {
                                    "label": "Add Folder",
                                    "action": function () {
                                        var selectedId = $('#tree').jstree('get_selected');
                                        // showCreateFolderWindow(selectedId);
                                    }
                                },
                                deleteItem: {
                                    "label": "Delete Folder",
                                    "action": function () {
                                        //  deleteFolder($node.id);

                                    }
                                }
                            };
                        }

                    }
                }
            });

    });
}

