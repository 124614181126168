import { initRelatedProducts } from './components/products/relatedProducts';
import { initProducts } from './components/products/products';
import { initRanges } from './components/ranges/ranges';
import { initOrders } from './components/orders/orders';
import { initTagging } from './components/tagging/tagging';
import { initTagFiles } from './components/tagging/tagFiles';
import { initStockUpdate } from './components/products/pricing';
import { searchTable } from './components/core/searchTable';
import { initMediaLibrary } from './components/media/media';
import { initDelivery } from './components/delivery/delivery';
import { initCustomers } from './components/customers/customers';
import { initMedia } from './components/media/media';
import { initCategories } from './components/categories/categories';
import { initDiscounts } from './components/discounts/discounts';
import { initPromotions } from './components/promotions/promotions';

import 'jquery-ui-bundle';

initRelatedProducts();
initProducts();
searchTable();
initOrders();
initTagging();
initTagFiles();
initStockUpdate();
initMediaLibrary();
initDelivery();
initRanges();
initCustomers();
initMedia();
initCategories();
initDiscounts();
initPromotions();
$('.datepicker').datepicker({ dateFormat: 'dd/mm/yy' });