import { createPercentageInput, createCurrencyInput } from './../actionCommon';

export default function basketItemDiscountAction(container, title, helpText) {
    var data = {  discountType: '', value: '' };

    function render() {
        return `<div class="rule-action-editor">
                    <div class="row">
                        <div class="col-md-3"><h3>${title}</h3>
                            <small><i>${helpText}</i></small>
                        </div>
                        <div class="col-md-9 js-rule-actions">
                            ${renderDiscount()}
                        </div>
                    </div>
                </div>`;
    }

    function renderDiscount() {
        return `<div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="js-action-discount-type col-md-3">
                                <select class="form-control">
                                    <option ${data.discountType == 0 ? 'selected' : ''} value="0">Percentage</option>
                                    <option ${data.discountType == 1 ? 'selected' : ''} value="1">Fixed Amount</option>
                                </select>
                                <small>Discount Type</small>
                            </div>
                            <div class="js-rule-action-value col-md-4">
                                ${data.discountType == 1 ? createCurrencyInput(data) : createPercentageInput(data)}
                                <small>Discount Value</small>
                            </div>
                        </div>
                    </div> 
                </div>
                `;
    }

    function validate() {
        var d = getValue();
        var valid = true;

        if (!isNumeric(d.value)) {
            valid = false;
        }
   
        if (!valid) {
            container.addClass('error');

        } else {
            container.removeClass('error');
        }
        return valid;
    }

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    function getValue() {
        data.discountType = container.find('.js-action-discount-type select').val();
        data.value = container.find('.js-rule-action-value input').val();
        return data;
    }

    function setValue(action) {
        if (action == null) {
            data = { discountType: 0, value :'' };
        } else {
            data = action;
        }
        container.html(render());
    }

    function bind() {
        container.html(render(data));

        container.off('change', '.js-action-discount-type select');
        container.on('change', '.js-action-discount-type select', function () {
            data.discountType = $(this).val();
            container.html(render(data));
            console.log(data);
        });
    }

    bind();

    return {
        getValue, setValue, validate
    };
}