import { uploadToMediaLibrary } from '../core/mediaLibrary';

export function initRelatedProducts() {
    $(document).on('change', '#RelatedThumbnailFile', function (e) {
        var path = 'product/' + $(this).attr('data-id');
        uploadToMediaLibrary($('#RelatedThumbnailFile')[0], path, function (res) {
            $.each(res, function (i, data) {
                $('#RelatedThumbnailImage').html('<img src="' + data.remoteHost + '" style="max-width:100px;max-height:100px;" />');
                $('#RelatedThumbnailId').val(data.url);
            });
        });
    });

    $(document).on('click', '#RelatedModalForm .submit-form', function (e) {
        e.preventDefault();
        var id = $('#RelatedId').val();
        var code = $('#RelatedCode').val();
        var title = $('#RelatedTitle').val();
        var hexCode = $('#RelatedHexCode').val();
        var groupTitle = $('#RelatedGroupTitle').val();
        var sortOrder = $('#RelatedSortOrder').val();
        var thumbnailId = $('#RelatedThumbnailId').val();
        var thumbnail = $('#RelatedThumbnailImage img').length ? $('#RelatedThumbnailImage img').attr('src') : '';
        var html = '<tr data-code="' + code + '" data-hexcode="' + hexCode + '"data-sortOrder="' + sortOrder + '" data-id="' + id + '" data-thumbnailId="' + thumbnailId + '" data-title="' + title + '" data-groupTitle="' + groupTitle + '"><td>' + code + '</td><td>' + (thumbnail.length > 0 ? '<img src="' + thumbnail + '" style="max-width:100px;max-height:100px;"/>' : '') + '</td><td>' + hexCode + '</td><td>' + title + '</td><td>' + groupTitle + '</td><td>' +  sortOrder+ '</td><td><a href="#" class="remove-related">Remove</a></td></tr>';
        $('#RelatedProductsList').find('tbody').append(html);
        $('#RelatedModal').find('.close').trigger('click');
        //$('body').removeClass('modal-open');
        //$('.modal-backdrop').remove();
    });

    $("#RelatedCode").autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/products/search",
                data: {
                    search: request.term
                },
                success: function (data) {
                    response($.map(data, function (item) {
                        return {
                            label: '[' + item.Code + '] ' + item.Name,
                            value: item.Code,
                            name: item.Name,
                            id: item.Id
                        };
                    }));
                }
            });
        },
        appendTo: "#RelatedModal",
        minLength: 3,
        select: function (event, ui) {
            if (ui.item) {
                $('#RelatedName').val(ui.item.name);
                $('#RelatedId').val(ui.item.id);
                //if (!$('#RelatedProductsList tr[data-id=' + ui.item.value + ']').length) {
                //    $('#RelatedProductsList tbody').append('<tr data-id="' + ui.item.value + '"><td>' + ui.item.value + '</td><td>' + ui.item.name + '</td><td><a href="#" class="remove-related">Remove</a></td></tr>');
                //}
            }
        },
        open: function () {
            $(this).removeClass("ui-corner-all").addClass("ui-corner-top");
        },
        close: function () {
            $(this).removeClass("ui-corner-top").addClass("ui-corner-all");
        }
    });
}