import { renderActionType, percentageInput, currencyInput } from './../actionCommon';

export default function basketFreeItemAction(container) {
    var data = {
        actionType: 0, value: { buyQuantity: 1, getQuantity: 1, getProduct: '', discount: 100, prompt: '', cycles: '' } };

    function render() {
        return `<div class="rule-action-editor">
                    <div class="row">
                        <div class="col-md-3"><h3>Buy X Get Y Discount</h3>
                            <small><i>The following actions will be applied if all rules pass.</i></small>
                        </div>
                        <div class="col-md-9 js-rule-actions">
                            ${renderDiscount()}
                        </div> 
                    </div>
                </div>`;
    }

    function renderDiscount() {
        console.log('9');
        console.log(data);
        return `<div class="row">
                    <div class="js-action-source col-md-2">
                        <fieldset style="margin-bottom:15px;"><legend>Buy</legend>
                        <div class="row">
                            <div class="col-md-12">     
                            <input type="text" class="form-control js-buy-quantity" placeholder="Buy" value="${data.buyQuantity}">
                            <small>Buy Quantity</small>
                               </div>
                        </div>
                        </fieldset>
                    </div>
                    <div class="js-action-target col-md-10">
                        <fieldset style="margin-bottom:15px;"><legend>Get</legend>
                        <div class="row">
                            <div class="col-md-3">                  
                                <input class="form-control js-get-quantity" placeholder="Get" value="${data.getQuantity}"" />
                                <small>Get Quantity</small>          
                            </div>
                            <div class="js-action-target-product col-md-7">
                                <input class="form-control js-get-product" placeholder="Get" value="${data.getProduct}"" />
                                <small>of this product</small>
                            </div>
                        </div>
                        </fieldset> 
                    </div>
                </div>
                <div class="row">
                 <div class="js-action-target col-md-12">
                    <fieldset><legend>Discount</legend>
                      <div class="row">
                    <div class="col-md-3">
                        <select class="form-control js-action-discount-type ">
                            <option ${data.discountType == 0 ? 'selected' : ''} value="0">Percentage</option>
                            <option ${data.discountType == 1 ? 'selected' : ''} value="1">Fixed Amount</option>
                        </select>
                        <small>Discount Type</small>
                    </div>
                    <div class="js-action-discount-value col-md-3">
                        ${data.discountType == 1 ? currencyInput(data.discount) : percentageInput(data.discount)}
                        <small>Discount Value</small>
                    </div>
                    <div class="js-action-discount-cycles col-md-4">
                        <input class="form-control" placeholder="unlimited" value="${data.cycles}"" />
                        <small>Number of times discount can apply</small>
                    </div>
                </div>
                 </div>
                </div>`;
    }

    function validate() {
       
        return true;
    }

    function getValue() {
        //data.actionType = container.find('.js-action-discount-type select').val();
        data = {
            buyQuantity: container.find('.js-buy-quantity').val(),
            getQuantity: container.find('.js-get-quantity').val(),
            getProduct: container.find('.js-get-product').val(),
            discountType: container.find('.js-action-discount-type').val(),
            discount: container.find('.js-action-discount-value input').val(),
            cycles: container.find('.js-action-discount-cycles input').val()
        };
        return data;
    }

    function setValue(action) {
        if (action == null) {
            data = {buyQuantity: 1, getQuantity: 1, getProduct: '', discountType: 0, discount:0, cycles:''};
        } else {
            data = action;
        }
        container.html(render(data));
    }

    function bind() {
        container.html(render(data));
        //$(document).off('change', '.js-rule-action-variable select');
        //$(document).on('change', '.js-rule-action-variable select', function () {
        //    var actionType = $(this).val();
        //    var action = actionTypes.filter(i => i.type == actionType)[0];
        //    container.find('.js-rule-action-type').html(renderActionType(action, actionTypes));
        //});
    }

    bind();

    return {
        getValue, setValue, validate
    };
}