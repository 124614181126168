import 'bootstrap';
import tinyAlert from './../core/tinyAlert';

export function initRanges() {
    $(document).on('click', '#SaveRange', function (e) {
        e.preventDefault();
        saveRange($(this));
    });

    $(document).on('submit', '#QuickAddRange form', function (e) {
        e.preventDefault();
        addRange();
    });


    $('.gallery-images').sortable({
        stop: function (e) {
            $('#CoverImage').attr('src', $('#product-images .gallery').first().find('.image').attr('href'));
        }
    });

    $(document).on('change', '#RangeForm .upload-image', function (e) {
        e.preventDefault();
        var t = $(this).attr('data-target');
        uploadRangeImage($(this),t);
    });

    $(document).on('click', '#RangeForm .remove-image', function (e) {
        e.preventDefault();
        removeImage($(this));
    });

    $("#Related").autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/products/search",
                data: {
                    search: request.term
                },
                success: function (data) {
                    response($.map(data, function (item) {
                        return {
                            label: '[' + item.Code + '] ' + item.Name,
                            value: item.Code,
                            name: item.Name
                        };
                    }));
                }
            });
        },
        minLength: 3,
        select: function (event, ui) {
            if (ui.item) {
                if (!$('#RelatedProductsList tr[data-id=' + ui.item.value + ']').length) {
                    $('#RelatedProductsList tbody').append('<tr data-id="' + ui.item.value + '"><td>' + ui.item.value + '</td><td>' + ui.item.name + '</td><td><a href="#" class="remove-related">Remove</a></td></tr>');
                }
            }
        },
        open: function () {
            $(this).removeClass("ui-corner-all").addClass("ui-corner-top");
        },
        close: function () {
            $(this).removeClass("ui-corner-top").addClass("ui-corner-all");
        }
    });

    $(document).on('click', '#RangesForm button[type=submit]', function (e) {
        DoRangeSearch();
    });

    $(document).on('click', '#RangesForm .options input[type=checkbox]', function (e) {
        e.preventDefault();
        DoRangeSearch();
    });

    $(document).on('keydown', '#RangesForm #search', function (e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            DoRangeSearch();
        }
    });


    $(document).on('click', '#RangesForm .pagination li a', function (e) {
        e.preventDefault();
        var pageId = parseInt($(this).data('pageid'));
        $('#CurrentPage').val(pageId);
        DoRangeSearch();
    });
    $(document).on('click', '#RangesForm .reorder', function (e) {
        e.preventDefault();
        var sortField = $(this).attr('data-sortfield');
        var currentSortField = $('#SortField').val();
        var reverse = $('#Reverse').val();
        console.log(reverse);
        if (sortField == currentSortField) {
            $('#SortField').val(sortField);
            $('#Reverse').val(reverse == "True" ? "False" : "True");
        } else {
            $('#SortField').val(sortField);
        }
        DoRangeSearch();
    });

    $(document).on('click', '#BulkAddRangeProductsBtn', function (e) {
        e.preventDefault();
        bulkAddProductsToRange($('#BulkAddRangeProducts').val());
    });
    $(document).on('keydown', '#BulkAddRangeProducts', function (e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            bulkAddProductsToRange($('#BulkAddRangeProducts').val());
        }
    });
    
}

function bulkAddProductsToRange(codes) {
    $.ajax({
        url: '/products/getbycodes?codes=' + codes,
        type: 'Get',
        processData: false,
        success: function (data) {
            $.each(data, function (i, v) {
                if (!$('#RelatedProductsList tr[data-id=' + v.Code + ']').length) {
                    $('#RelatedProductsList tbody').append('<tr data-id="' + v.Code + '"><td>' + v.Code + '</td><td>' + v.Name + '</td><td><a href="#" class="remove-related">Remove</a></td></tr>');
                }
            })
        }
    });
}

function addRange() {
    if ($('#QuickAddRange form').valid()) {
        var that = $('#QuickAddRange form').find('button.btn-success');
        var data = $('#QuickAddRange form').serialize();
        console.log(data);
        var buttonText = that.html();
        that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Saving');
        $.ajax({
            url: '/ranges/add',
            type: 'POST',
            data: data,
            processData: false,
            success: function (data) {
                that.html(buttonText);
                $('#QuickAddRange .close').trigger('click');
                window.location.href = '/ranges/' + data.item;
            }
        });
    }
}

function saveRange(that) {
    var range = {};
    var data = $('#RangeForm').serializeArray();


    $(data).each(function (index, obj) {
        range[obj.name] = obj.value;
    });

    var desc = $('#Description_ifr').contents().find('body')[0].innerHTML;
    desc = desc.replace(' data-mce-bogus="1"', '');
    range.Description = desc;
    //var metaDesc = $('#MetaDescription_ifr').contents().find('body')[0].innerHTML;
    //metaDesc = metaDesc.replace(' data-mce-bogus="1"', '');
    //range.MetaDescription = desc;


    var images = [];
    $('#product-images .gallery').each(function (i, v) {
        var caption = $(this).find('textarea').val();
        var id = $(this).find('.image').attr('data-id');
        var url = $(this).find('.image').attr('data-url');
        images.push({ Id: id, caption: caption, sortOrder: i, url: url });
    });

    range.images = images;


    var categories = [];
    $('#Category option:selected').each(function (i, v) {
        categories.push($(this).val());
    });
    range.categories = categories;


    var products = [];
    $('#RelatedProductsList tbody tr').each(function (i, v) {
        if ($(this).attr('data-id') != 'undefined')
            products.push($(this).attr('data-id'));
    });
    range.productCodes = products;

    console.log(range);
    var buttonText = that.html();
    that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Saving');
    $.ajax({
        url: '/ranges/update',
        type: 'POST',
        data: JSON.stringify(range),
        processData: false,
        contentType: 'application/json',
        success: function (data) {
            tinyAlert(data.message, data.status == "ok");
            that.html(buttonText);
        }
    });
}

function uploadRangeImage(item, target) {
    var data = new FormData();
    for (var x = 0; x < item[0].files.length; x++) {
        data.append('image[file]', item[0].files[x]);
    }

    var imageBlock = $('#' + target);
    var desc = item.attr('data-description') == 'true';
    var id = $('#Id').val();
    $.ajax({
        url: '/api/file?path=range/' + id,
        type: 'POST',
        data: data,
        processData: false, // Don't process the files
        contentType: false, // Set content type to false as jQuery will tell the server its a query string request
        success: function (res) {
            $.each(res, function (i, v) {
                var data = v;
                var str = '<div class="gallery"><div class="inner"><a class="image" target="_blank" href="/content' +
                    data.url + '" style="background:url(\'/content' + data.url + '\'") no-repeat" data-id="" data-url="' + data.url + '"></a>';

                if (desc) {
                    str = str + '<div class="desc"><textarea placeholder="Add a caption here"></textarea></div>';
                }

                str = str + '<div class="image-footer"><a href="#" class="remove-image">Remove</a><i class="fa fa-bars"></i></div></div></div>'

                imageBlock.append(str)
            });


            $('#CoverImage').attr('src', $('#product-images .gallery').first().find('.image').attr('href'));
            imageBlock.sortable({
                stop: function (e) {
                    $('#CoverImage').attr('src', $('#product-images .gallery').first().find('.image').attr('href'));
                }
            });
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.responseText) {
                errors = JSON.parse(jqXHR.responseText).errors
                alert('Error uploading image: ' + errors.join(", ") + '. Make sure the file is an image and has extension jpg/jpeg/png.');
            }
        }
    });
}

function removeImage(item) {
    item.closest('.gallery').remove();
    if ($('#product-images .gallery').length > 0) {
        $('#CoverImage').attr('src', $('#product-images .gallery').first().find('.image').attr('href'));
    } else {
        $('#CoverImage').attr('src', '/content/noimage.jpg');
    }
}

function DoRangeSearch() {
    var data = $('#RangeSearch').serialize();

    $.ajax({
        url: "/ranges/find",
        data: data,
        success: function (data) {
            $('.productresults').hide().html(data).fadeIn();
        },
        cache: false
    });
}