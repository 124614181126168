import { createRuleOperator, createRuleTarget, createRuleComparer, createCurrencyInput } from './../ruleCommon.js';


export default function customerEqualityRule(ruleTypes, operators, comparers) {

    function renderRule(rule) {      
        var ruleDef = ruleTypes.find(r => r.type == rule.entity.ruleName);
        var ruleOpDef = operators.find(r => r.id == rule.entity.operator);
        var ruleCompDef = comparers.find(r => r.id == rule.entity.comparer);

        return `<div class="col-md-2">${ruleDef == undefined ? '' : ruleDef.ruleName}</div>
                    <div class="col-md-2">${ruleOpDef == undefined ? '' : ruleOpDef.name}</div>
                    <div class="col-md-2">${ruleCompDef == undefined ? '' : ruleCompDef.name}</div>
                    <div class="col-md-3">${rule.entity.value}</div>
                    <div class="col-md-2">${rule.entity.errorMessage != undefined && rule.entity.errorMessage != null ? rule.entity.errorMessage : ''}</div>
               `;
    }

    function renderEditor(rule) {
        var ruleDef = ruleTypes.find(r => r.type == rule.entity.ruleName);
        return `<div class='js-rule-target col-md-2'>
                            ${createRuleTarget(ruleTypes, rule)}
                    </div>
                    <div class='js-rule-operator col-md-2'>${createRuleOperator(operators, rule, ruleDef)}</div>
                    <div class='js-rule-comparer col-md-2'>${createRuleComparer(comparers, rule, ruleDef)}</div>
                    <div class='js-rule-value col-md-3'><input type="text" class="form-control" placeholder="Codes" value="${rule.entity.value == undefined || rule.entity.value == null ? '' : rule.entity.value}" /></div>
                    <div class='js-rule-message col-md-3'><input type="text" class="form-control" placeholder="Optional Error Message" value="${rule.entity.errorMessage != undefined && rule.entity.errorMessage != null ? rule.entity.errorMessage : ''}"/></div>
                    <div class="rule-edit"><a href="" class="js-rule-edit-done"><i class="fa fa-check"></i></a></div>
                    <div class="rule-delete"><a href="" class="js-rule-delete"><i class="fa fa-times"></i></a></div>
                   `;
    }

    function validateRule(rule, container) {
        rule.entity.ruleName = container.find('.js-rule-target select').val();
        rule.entity.operator = container.find('.js-rule-operator select').val();
        rule.entity.comparer = container.find('.js-rule-comparer select').val();
        rule.entity.value = container.find('.js-rule-value input').val();
        rule.entity.errorMessage = container.find('.js-rule-message input').val();

        if (rule.entity.ruleName == '' || rule.entity.operator == '' || rule.entity.comparer == '' || rule.entity.value == '')
        {
            container.addClass('error');

        } else {
            container.removeClass('error');
        }
        return rule;
    }

    return {
        renderRule, renderEditor, validateRule
    };
}