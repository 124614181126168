import 'bootstrap';
import tinyAlert from './../core/tinyAlert';

export function initDelivery() {
    $(document).on('click', '#AddChargeButton', function (e) {
        e.preventDefault();
        clearModal('#ExtraChargesModal');
        $('#ExtraChargesModal').modal('show');
    });

    $(document).on('click', '#AddAccountRuleButton', function (e) {
        e.preventDefault();
        clearModal('#AccountRulesModal');
        $('#AccountRulesModal').modal('show');
    });

    $(document).on('click', '.edit-charge', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var t = $(this).html();
        var that = $(this);
        that.html('<i class="fa fa-gear fa-spin"></i> Working');
        $.get('/Delivery/GetDeliveryExtraCharge?id=' + id, function (res) {
            $('#ExtraChargesModal .modal-content').html(res);
            $("#ExtraChargesModal").val(null).trigger("change");
            $('#ExtraChargesModal').modal('show');
            that.html(t);
        });
    });

    $(document).on('click', '.edit-delivery-rule', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var t = $(this).html();
        var that = $(this);
        that.html('<i class="fa fa-gear fa-spin"></i> Working');
        $.get('/Delivery/GetDeliveryAccountRule?id=' + id, function (res) {
            $('#AccountRulesModal .modal-content').html(res);
            $("#AccountRulesModal").val(null).trigger("change");
            $('#AccountRulesModal').modal('show');
            that.html(t);
        });
    });

    $(document).on('submit', '#DeliveryMethodForm', function (e) {
        e.preventDefault();
        var data = $(this).serialize();
        $.post('/delivery/addmethod', data, function (res) {
            tinyAlert(data.message, data.status == "ok");
            if (res.status == "ok") {
                window.location.href = '/delivery';
            }
        });
    });
    $(document).on('submit', '#DeliveryMethodUpdateForm', function (e) {
        e.preventDefault();
        var data = $(this).serialize();
        $.post('/delivery/update', data, function (res) {
            tinyAlert(res.message, res.status == "ok");
        });
    });

    $(document).on('click', '#DeleteDeliveryMethod', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        if (confirm('Are you sure you want to delete this method? This cannot be undone!')) {
            $.post('/delivery/DeleteMethod?id=' + id, {}, function (res) {
                tinyAlert(res.message, res.status == "ok");
                if (res.status == "ok") {
                    window.location.href = '/delivery';
                }
            });
        }
    });

    $(document).on('submit', '#DeliveryMethodEmailForm', function (e) {
        e.preventDefault();
        formDeliveryUpdate($(this));
    });

    $(document).on('submit', '#DeliveryMethodExtraCharge', function (e) {
        e.preventDefault();
        var id = $('#DeliveryMethodId').val();
        formDeliveryUpdate($(this), function () {
            $.get('/Delivery/GetDeliveryExtraCharges?id=' + id, function (res) {
                $('#ExtraChargesTable').html(res);
                $('#ExtraChargesModal').find('.close').trigger('click');
                clearModal('#ExtraChargesModal');
            });
        });
    });
    $(document).on('submit', '#DeliveryAccountRuleForm', function (e) {
        e.preventDefault();
        var id = $('#AccountRuleDeliveryMethodId').val();
        formDeliveryAccountRuleUpdate($(this), function (res) {
            var id = res.id;
            $.get('/Delivery/GetDeliveryAccountRules?id=' + id, function (res) {
                $('#AccountRulesTable').html(res);
                $('#AccountRulesTable').find('.close').trigger('click');
                clearModal('#AccountRulesModal');
                $('#AccountRulesModal').modal('hide');
                $('#AccountRulesModal .close').trigger('click');
            });
        });
    });
    $(document).on('click', '.remove-delivery-rule', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var deliverymethodid = $(this).attr('data-deliverymethodid');
        var t = $(this).html();
        var that = $(this);
        that.html('<i class="fa fa-spinner fa-spin"></i> Working');
        $.get('/Delivery/DeleteDeliveryAccountRule?id=' + id, function (res) {
            $.get('/Delivery/GetDeliveryAccountRules?id=' + deliverymethodid, function (res) {
                $('#AccountRulesTable').html(res);
                $('#AccountRulesTable').find('.close').trigger('click');
                clearModal('#AccountRulesModal');
                $('#AccountRulesModal .close').trigger('click');
            });
        });
    });
}

function formDeliveryUpdate(form, callback) {
    var button = form.find('button[type="submit"]');
    if (form.valid()) {
        var buttonText = button.html();
        button.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span> Working');
        var data = form.serialize();
        $.post(form.attr('action'), data,
            function (res) {
                tinyAlert(res.message, res.status == "ok");
                button.html(buttonText);
                if (callback != 'undefined' && typeof callback == "function") {
                    callback(res);
                }
            });
    }
}

function formDeliveryAccountRuleUpdate(form, callback) {
    var button = form.find('button[type="submit"]');
    if (form.valid()) {
        var buttonText = button.html();
        button.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span> Working');
        var data = form.serialize();
        $.post(form.attr('action'), data,
            function (res) {
                tinyAlert(res.message, res.status == "ok");
                button.html(buttonText);
                if (callback != 'undefined' && typeof callback == "function") {
                    callback(res);
                }
            });
    }
}

export function clearModal(modalSelector) {
    $(modalSelector).find('input').val('');
    $(modalSelector).find('textarea').val('');
    $(modalSelector).find('.uploadpreview img').remove();

}