import 'jstree';
import tinyAlert from './../core/tinyAlert';

export function initCategories() {
    $(document).on('submit', '#CategoryUpdate', function (e) {
        e.preventDefault();
        var data = $(this).serialize();
        $.ajax({
            url: '/categories/update',
            type: 'POST',
            data: data,
            processData: false,
            success: function (data) {
                tinyAlert(data.message, data.status == "ok");
            }
        });
    });


    $(function () {
        $('#CategoryTree')
            .on('changed.jstree', function (e, data) {
                var i, j, r = [], n, t, p = [];
                for (i = 0, j = data.selected.length; i < j; i++) {
                    r.push(data.instance.get_node(data.selected[i]).text);
                    n = data.instance.get_node(data.selected[i]).id;
                    t = data.instance.get_node(data.selected[i]).text;
                    p = data.instance.get_path(data.selected[i]);
                }

                if (data.selected.length > 0) {
                    $.ajax({
                        url: "/Categories/Get",
                        data: { id: n },
                        success: function (data) {
                            $('.listing').hide().html(data).fadeIn();
                        },
                        cache: false
                    });
                }

            })
            .jstree({
                'core': {
                    'check_callback': function (op, node, par, pos, more) {
                        if (more && more.dnd) {
                            return more.pos !== "i" && par.id == node.parent;

                        }
                        return true;
                    },
                    'data': {
                        'url': '/Categories/GetChildren',
                        'data': function (node) {
                            return { 'id': node.id };
                        }

                    }
                },
                "plugins": ["dnd", "crrm"]
            });

    });
}