import { uploadToMediaLibrary } from '../core/mediaLibrary';
import { autocomplete } from 'jquery-ui-bundle';
import validate from 'jquery-validation'
import selectize from 'selectize';
import tinyAlert from './../core/tinyAlert';

export function initProducts() {
    $('#QuickAddProduct form').validate({});

    $(document).on('submit', '#QuickAddProduct', function (e) {
        e.preventDefault();
        addProduct();
    });


    
    $(document).on('click', '.products #Save', function (e) {
        e.preventDefault();
        saveProduct($(this));
    });

    $(document).on('click', '.tagremove', function (e) {
        e.preventDefault();
        $(this).closest('tr').remove();
    });

    $(document).on('click', '.removecat', function (e) {
        e.preventDefault();
        $(this).closest('tr').remove();
    });

    $(document).on('click', '.remove-related', function (e) {
        e.preventDefault();
        $(this).closest('tr').remove();
    });

    $(document).on('click', '.remove-video', function (e) {
        e.preventDefault();
        $(this).closest('tr').remove();
    });

    $(document).on('click', '.add-video', function (e) {
        e.preventDefault();

        var videoUrl = $('#product-videos').find('#VideoUrl').val();
        var thumbnailUrl = $('#product-videos').find('#ThumbnailUrl').val();
        if (videoUrl.length < 5) return;

        var vUrl = videoUrl;
        if (!vUrl.includes("embed")) {
            vUrl = "https://www.youtube.com/embed/" + videoUrl;
        }

        $('#product-videos table tbody').append(`<tr data-id="">
                                <td class="videourl" data-videourl="${videoUrl}"> <iframe width="560" height="315" src="${vUrl}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></td>
                                <td class="thumbnailurl" data-thumbnailurl="${thumbnailUrl}"><img src="${thumbnailUrl}" style="max-width:150px;"/></td>
                                <td><a href="#" class="remove-video btn btn-primary">Remove</a></td>
                            </tr>`);
    });

    $(document).on('click', '#product-files .delete-product-file', function (e) {
        e.preventDefault();
        $(this).closest('tr').remove();
    });


    $('.select-tags').selectize({
        create: true,
        render: {
            option_create: function (data, escape) {
                return '<div class="create">Create <strong>' + escape(data.input) + ' as new tag</strong>&hellip;</div>';
            }
        }, create: function (input, callback) {
            var sortOrder = Object.keys(this.options).length;
            var facetId = this.$input[0].dataset.facetId;
            $.ajax({
                type: "POST",
                url: '/tagging/CreateTag',
                data: { facetId: facetId, name: input, sortOrder: sortOrder },
                success: function (res) {
                    callback({ value: res.Id, text: input });
                }
            });
        }
    });

    $('.selectize-category').selectize();
    $('.selectize-range').selectize();

    $('.selectize-tag').selectize({
        create: true,
        render: {
            option_create: function (data, escape) {
                return '<div class="create">Create <strong>' + escape(data.input) + ' as new tag</strong>&hellip;</div>';
            }
        }, create: function (input) {

            return {
                value: input,
                text: input
            }
        }
    });

    $('.gallery-images').sortable({
        stop: function (e) {
            //$('#CoverImage').attr('src', $('#product-images .gallery').first().find('.image').attr('href'));
        }
    });

    $(document).on('change', '#Product .upload-image', function (e) {
        e.preventDefault();
        var t = $(this).attr('data-target');
        uploadImage($(this),t);
    });


    $(document).on('click', '.remove-image', function (e) {
        e.preventDefault();
        removeImage($(this));
    });

    $(".searchchop").autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/products/search",
                data: {
                    search: request.term,
                    type: 7
                },
                success: function (data) {
                    response($.map(data, function (item) {
                        return {
                            label: '[' + item.Code + '] ' + item.Name,
                            value: item.Code
                        };
                    }));
                }
            });
        },
        minLength: 3,
        open: function () {
            $(this).removeClass("ui-corner-all").addClass("ui-corner-top");
        },
        close: function () {
            $(this).removeClass("ui-corner-top").addClass("ui-corner-all");
        }
    });
    $(".searchchevron").autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/products/search",
                data: {
                    search: request.term,
                    type: 5
                },
                success: function (data) {
                    response($.map(data, function (item) {
                        return {
                            label: '[' + item.Code + '] ' + item.Name,
                            value: item.Code
                        };
                    }));
                }
            });
        },
        minLength: 3,
        open: function () {
            $(this).removeClass("ui-corner-all").addClass("ui-corner-top");
        },
        close: function () {
            $(this).removeClass("ui-corner-top").addClass("ui-corner-all");
        }
    });

    $(".searchproduct").autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/products/search",
                data: {
                    search: request.term
                },
                success: function (data) {
                    response($.map(data, function (item) {
                        return {
                            label: '[' + item.Code + '] ' + item.Name,
                            value: item.Code
                        };
                    }));
                }
            });
        },
        minLength: 3,
        open: function () {
            $(this).removeClass("ui-corner-all").addClass("ui-corner-top");
        },
        close: function () {
            $(this).removeClass("ui-corner-top").addClass("ui-corner-all");
        }
    });
    $(".searchfiles").autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/media/search",
                data: {
                    search: request.term,
                },
                success: function (data) {
                    response($.map(data, function (item) {
                        return {
                            label: item.Filename,
                            value: item.Id
                        };
                    }));
                }
            });
        },
        minLength: 3,
        open: function () {
            $(this).removeClass("ui-corner-all").addClass("ui-corner-top");
        },
        close: function () {
            $(this).removeClass("ui-corner-top").addClass("ui-corner-all");
        },
        select: function (event, ui) {
            console.log(ui);
            $.ajax({
                url: "/media/get/" + ui.item.value,
                success: function (data) {
                    var html = `<tr data-id="${data.Id}">
                            <td>${data.Filename}</td>
                            <td>${data.FileType}</td>
                            <td>${data.FileSize}</td>
                            <td>${data.Created}</td>
                            <td>${data.Description}</td>
                            <td style="text-align:center;width:60px;">
                                    <img src="${data.ThumbnailUrl}" style="max-width:40px;">
                            </td>
                            <td>
                                <div class="btn-group">
                                    <button class="btn btn-default dropdown-toggle" data-toggle="dropdown">
                                        ...
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-right">
                                        <li>
                                            <a href="#" class="delete-product-file" data-id="${data.Id}">Delete</a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>`;
                    $('#product-files tbody').append(html);
                    $(".searchfiles").val('');
                }
            });
        }
    });
}

function saveProduct(that) {

    var product = {};
    var data = $('#Product').serializeArray();

    $(data).each(function (index, obj) {
        product[obj.name] = obj.value;
    });

    var images = [];
    $('#product-images .gallery').each(function (i, v) {
        var caption = $(this).find('textarea').val();
        var id = $(this).find('.image').attr('data-id');
        var url = $(this).find('.image').attr('data-url');
        var isGalleryImage = $(this).find('.user-gallery input[type=checkbox]').prop('checked');
        images.push({ id: id, caption: caption, ordering: i, url: url, isGalleryImage: isGalleryImage });
    });

    product.productImages = images;

    var images3d = [];
    $('#ProductImages3d .gallery').each(function (i, v) {
        var fileId = $(this).find('.image').attr('data-file-id');
        var url = $(this).find('.image').attr('data-url');
        images3d.push({ fileId: fileId, sortOrder: i, url: url });
    });

    product.productImages3d = images3d;

    var tags = [];
    $('.select-tags option:selected').each(function (i, v) {
        tags.push($(this).val());
    });
    product.tags = tags;

    var ranges = [];
    $('#Range option:selected').each(function (i, v) {
        ranges.push($(this).val());
    });
    product.ranges = ranges;

    var categories = [];
    $('#Category option:selected').each(function (i, v) {
        categories.push($(this).val());
    });
    product.categories = categories;

    product.TakeStockFrom = {
        Code: $('#TakeStockFrom').val()
    };

    var files = [];
    $('#product-files tr').each(function (i, v) {
        files.push($(this).attr('data-id'));
    });
    product.fileIds = files.join(',');

    var videos = [];
    $('#product-videos tbody tr').each(function (i, v) {
        videos.push({
            id: $(this).attr('data-id'),
            videoUrl: $(this).find('.videourl').attr('data-videourl'),
            thumbnailUrl: $(this).find('.thumbnailurl').attr('data-thumbnailurl')
        });
    });
    console.log(videos);
    product.videos = videos;

    var related = [];
    $('#RelatedProductsList tbody tr').each(function (i, v) {
        if ($(this).attr('data-id') != undefined) {
            var relatedItem = { id: '', accessoryProductId: $(this).attr('data-id'), thumbnailId: '', title: '', groupTitle: '', sortOrder:'0' };
            if ($(this).attr('data-thumbnailId') != undefined) {
                relatedItem.thumbnailUrl = $(this).attr('data-thumbnailId');
            }
            if ($(this).attr('data-hexcode') != undefined) {
                relatedItem.hexCode = $(this).attr('data-hexcode');
            }
            if ($(this).attr('data-title') != undefined) {
                relatedItem.title = $(this).attr('data-title');
            }
            if ($(this).attr('data-code') != undefined) {
                relatedItem.code = $(this).attr('data-code');
            }
            if ($(this).attr('data-groupTitle') != undefined) {
                relatedItem.groupTitle = $(this).attr('data-groupTitle');
            }
            if ($(this).attr('data-sortOrder') != undefined) {
                relatedItem.sortOrder = $(this).attr('data-sortOrder');
            }
            if ($(this).attr('data-uid') != undefined) {
                relatedItem.id = $(this).attr('data-uid');
            }

            related.push(relatedItem);
        }
    });
    product.relatedProducts = related;

    console.log(product);
    var buttonText = that.html();
    that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Saving');
    $.ajax({
        url: '/products/update',
        type: 'POST',
        data: JSON.stringify(product),
        processData: false,
        contentType: 'application/json',
        success: function (data) {
            tinyAlert(data.message, data.status == "ok");
            that.html(buttonText);
        }
    });
}

function addProduct() {
    if ($('#QuickAddProduct form').valid()) {
        var that = $('#QuickAddProduct form').find('button.btn-success');
        var data = $('#QuickAddProduct form').serialize();
        console.log(data);
        var buttonText = that.html();
        that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Saving');
        $.ajax({
            url: '/products/add',
            type: 'POST',
            data: data,
            processData: false,
            success: function (data) {
                that.html(buttonText);
                console.log(data);
                if (data.status == "ok") {
                    tinyAlert(data.message, data.status == "ok");
                    $('#QuickAddProduct .close').trigger('click');
                    window.location.href = '/products/' + data.item;
                } else {
                    tinyAlert(data.message, data.status == "ok");
                }


            }
        });
    }
}

function uploadImage(item, target) {
    var data = new FormData();
    for (var x = 0; x < item[0].files.length; x++) {
        data.append('image[file]', item[0].files[x]);
    }

    var imageBlock = $('#' + target);
    var desc = item.attr('data-description') == 'true';
    var productId = $('#Id').val();
    $.ajax({
        url: '/api/file?path=products/' + $('#Code').val(),
        type: 'POST',
        data: data,
        processData: false, // Don't process the files
        contentType: false, // Set content type to false as jQuery will tell the server its a query string request
        success: function (res) {
            $.each(res, function (i, v) {
                var data = v;
                var str = '<div class="gallery"><div class="inner"><a class="image" target="_blank" href="/content' +
                    data.url + '" style="background:url(\'/content' + data.url + '\'") no-repeat" data-id="" data-url="' + data.url + '"></a>';

                if (desc) {
                    str = str + '<div class="desc"><textarea placeholder="Add a caption here"></textarea></div>';
                }

                str = str + '<div class="image-footer"><a href="#" class="remove-image">Remove</a><i class="fa fa-bars"></i></div></div></div>'

                imageBlock.append(str)
            });


          //  $('#CoverImage').attr('src', $('#product-images .gallery').first().find('.image').attr('href'));
            imageBlock.sortable({
                stop: function (e) {
                    //$('#CoverImage').attr('src', $('#product-images .gallery').first().find('.image').attr('href'));
                }
            });
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.responseText) {
                errors = JSON.parse(jqXHR.responseText).errors
                alert('Error uploading image: ' + errors.join(", ") + '. Make sure the file is an image and has extension jpg/jpeg/png.');
            }
        }
    });
}

function removeImage(item) {
    item.closest('.gallery').remove();
    if ($('#product-images .gallery').length > 0) {
     //   $('#CoverImage').attr('src', $('#product-images .gallery').first().find('.image').attr('href'));
    } else {
        $('#CoverImage').attr('src', '/content/noimage.jpg');
    }
}

function DoSearch() {
    var data = $('#ProductSearch').serialize();

    $.ajax({
        url: "/products/find",
        data: data,
        success: function (data) {
            $('.productresults').hide().html(data).fadeIn();
        },
        cache: false
    });
}

