export default function PromotionFilter(selector) {
    var data = [];
    var promotionType = 0;

    var container = $(selector);
    var ranges = JSON.parse(document.getElementById('RangeData').textContent);
    var categories = JSON.parse(document.getElementById('CategoryData').textContent);
    var deliveryMethods = JSON.parse(document.getElementById('DeliveryData').textContent);

    var operatorTypes = [
        { id: 0, name: 'IN' },
        { id: 1, name: 'NOT IN' }
    ];


    function bind() {
    
        $(selector).html(render());
        $('select.selectize').selectize();
        $(selector).on('click', '.js-add-promo-filter-btn', function (e) {
            e.preventDefault();
            data.push(getFilterTypes()[0].defaults);
            console.log(data);
            $(selector).find('.js-filter-rows').append(renderRow(getFilterTypes()[0].defaults));
            $('select.selectize').selectize();
        });
        $(selector).on('click', '.js-delete-promo-filter-btn', function (e) {
            e.preventDefault();
            $(this).closest('.row').remove();
        });
        $(selector).on('change', '.js-filter-type', function (e) {
            var idx = $(this).closest('.js-filter-row').index();
            data[idx].type = parseInt($(this).val());
            $(this).closest('.js-filter-row').replaceWith(renderRow(data[idx]));
            $('select.selectize').selectize();
        });

    };

    function getFilterTypes() {
        switch (parseInt(promotionType)) {
            case 0:
                return [
                    { id: 0, name: 'All Products', defaults: {} }
                ];
                break;
            case 1:
                return [
                    { id: 7, name: 'All Delivery Methods', defaults: { type: 7, operator: 0, value: '' } },
                    { id: 8, name: 'Specified Delivery Method', defaults: { type: 8, operator: 0, value: ''} }
                ];
                break;
            case 2:
                return [
                    { id: 1, name: 'Specified Products', defaults: { operator: 0, value: '' } }
                ];
                break;
            case 3:
                return [
                    { id: 0, name: 'All Products', defaults: {} },
                    { id: 1, name: 'Specified Products', defaults: { operator: 0, value: '' } },
                    { id: 2, name: 'Mouldings', defaults: { operator: 0, value: '' } },
                    { id: 3, name: 'Mountboards', defaults: { operator: 0, value: '' } },
                    { id: 4, name: 'Product Group', defaults: { operator: 0, value: '' } },
                    { id: 5, name: 'Category', defaults: { operator: 0, value: '' } },
                    { id: 6, name: 'Range', defaults: { operator: 0, value: '' } }
                ];
                break; 

            default:
                return [
                    { id: 0, name: 'All Products', defaults: {} }
                ]
        }
    }

    function render() {
        if (promotionType == 0)
            container.hide();
        else
            container.show();

        return `<div class="rule-action-editor">
                    <div class="row">
                        <div class="col-md-3"><h3>Filter</h3>
                            <small><i>Choose what gets discounted</i></small>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-12 js-filter-rows">
                                    ${data.map((o) => renderRow(o)).join('')}
                                </div>
                            </div>                            
                            <div class="row">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="col-md-12">
                                        <a href="#" class="js-add-promo-filter-btn btn btn-ribbon" style="height: 30px;padding: 5px 15px;margin-top: 5px;">Add Filter</a>
                                    </div>
                                    </div>
                                </div>
                            </div>      
                        </div>
                    </div>
                </div>`;
    }

    function renderRow(row) {
        return `<div class="row filter-row js-filter-row" style="margin-bottom:5px;">
                                <div class="col-md-3">
                                    <select class="form-control js-filter-type">
                                        ${getFilterTypes().map((o) => `<option value="${o.id}" ${row.type == o.id ? 'selected' : ''}>${o.name}</option>`).join('')}               
                                    </select>
                                    </div>
                                <div class="col-md-7 js-filter-type-editor">
                                    ${renderFilterTypeEditor(row)}
                                </div>
                                 <div class="col-md-1"><a href="" class="js-delete-promo-filter-btn" style="padding: 5px;display: inline-block;"><i class="fa fa-times fa fa-times btn-danger padding-5"></i></a></div>
                            </div>`;
    }

    function renderOperator(filter) {
        return `<select class="form-control js-filter-operator">
                       ${operatorTypes.map((o) => `<option value="${o.id}" ${filter.operator == o.id ? 'selected' : ''}>${o.name}</option>`).join('')}    
                    </select>`;
    }

    function renderSpecifiedProductLookup(filter) {
        return `<div class="row">
                    <div class="col-md-6">
                        ${renderOperator(filter)}
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control js-product-lookup js-filter-value" placeholder="Product Codes" value="${filter.value}"/>
                        <small>Comma-Separated List Required</small>
                    </div>               
                </div>`;
    }
    function renderSpecifiedProductGroupLookup(filter) {
        return `<div class="row">
                    <div class="col-md-6">
                        ${renderOperator(filter)}
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control js-product-lookup js-filter-value" placeholder="Product groups" value="${filter.value}"/>
                        <small>Comma-Separated List Required</small>
                    </div>               
                </div>`;
    }
    function renderSpecifiedProductRangeLookup(filter) {
        var vals = filter.value;
        return `<div class="row">
                    <div class="col-md-6">
                        ${renderOperator(filter)}
                    </div>
                    <div class="col-md-6">
                        <select class="form-control js-filter-value selectize" multiple  placeholder="Product Range">
                            ${ranges.map((o) => `<option value="${o.id}" ${$.inArray(o.id.toString(), vals) !== -1 ? 'selected' : ''}>${o.name}</option>`).join('')}
                        </select>
                    </div>               
                </div>`;

    }

    function renderCategoryLookup(filter) {
        var vals = filter.value;
        return `<div class="row">
                    <div class="col-md-6">
                        ${renderOperator(filter)}
                    </div>
                    <div class="col-md-6">
                        <select class="form-control js-filter-value selectize" multiple  placeholder="Product Range">
                            ${categories.map((o) => `<option value="${o.id}" ${$.inArray(o.id.toString(), vals) !== -1 ? 'selected' : ''}>${o.name}</option>`).join('')}
                        </select>
                    </div>               
                </div>`;

    }

    function renderDeliveryLookup(filter) {
        return `<div class="row">
                    <div class="col-md-6">
                        ${renderOperator(filter)}
                    </div>
                    ${filter.type == 8 ? renderDeliveryValueLookup(filter.value) : ''}              
                </div>`;
    }

    function renderDeliveryValueLookup(vals) {
        return `<div class="col-md-6">
                        <select class="form-control js-filter-value selectize" multiple  placeholder="Delivery Methods">
                            ${deliveryMethods.map((o) => `<option value="${o.id}" ${$.inArray(o.id.toString(), vals) !== -1 ? 'selected' : ''}>${o.name}</option>`).join('')}
                        </select>
                    </div>`;

    }

    function renderFilterTypeEditor(filter) {
        switch (filter.type) {
            case 0:
                return '';
                break;

            case 1:
                return renderSpecifiedProductLookup(filter);
                break;

            case 2:
                return '';
                break;

            case 3:
                return '';
                break;

            case 4:
                return renderSpecifiedProductGroupLookup(filter);
                break;

            case 5:
                return renderCategoryLookup(filter);
                break;

            case 6:
                return renderSpecifiedProductRangeLookup(filter);
                break;

            case 7:
                return '';
                break;

            case 8:
                return renderDeliveryLookup(filter);
                break;

            default:
                break;
        }
    }


    function getValue() {
        data = [];
        container.find('.js-filter-row').each(function (i, v) {
            var val = $(this).find('.js-filter-value').val();

            var filterType = $(this).find('.js-filter-type').val();
            var filterOperator = $(this).find('.js-filter-operator').length ? $(this).find('.js-filter-operator').val() : 0;
            var filterValue = val == null || val == '' ? [] : (Array.isArray(val) ? val :  val.split(','));
            data.push({ type: filterType, operator: filterOperator, value: filterValue });
        });
        return data;
    }
    function setValue(val, ptype) {
        data = val;
        promotionType = ptype;
        container.html(render());
        $('select.selectize').selectize();
    }

    function clear(ptype) {
        data = [];
        promotionType = ptype;
        container.html(render());
        $('select.selectize').selectize();
    }

    bind();

    return {
        bind, getValue, setValue, clear
    };
}