import './../core/tinyAlert';

export function initStockUpdate() {
    $(document).on('click', '#DataRefresh', function (e) {
        e.preventDefault();
        if (confirm('This process will take approximately 30 seconds. Do you want to continue?')) {
            var that = $(this);
            var buttonText = that.html();
            that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Updating');
            $.get(that.attr('href'), function (res) {
                //tinyAlert(data.Message, data.Status == "ok");datahealthcheck
                that.html(buttonText);
                $('#datahealthcheck').html(res);
            });
        }
    });
}