import './ruleEditor.scss';
import { datepicker } from 'jquery-ui-bundle';
import selectize from 'selectize';
import { createRuleOperator, createRuleTarget, createRuleComparer } from './ruleCommon.js';
import orderSubtotalRule from './ruleEditors/orderSubtotalRule';
import promotionCodeEqualityRule from './ruleEditors/promotionCodeEqualityRule';
import productContainedInRule from './ruleEditors/productContainedInRule';
import productGroupContainedInRule from './ruleEditors/productGroupContainedInRule';
import productTypeRule from './ruleEditors/productTypeRule';
import customerEqualityRule from './ruleEditors/customerEqualityRule';
import datePromotionRule from './ruleEditors/datePromotionRule';
import deliveryCodeEqualityRule from './ruleEditors/deliveryCodeEqualityRule';
import productQuantityRule from './ruleEditors/productQuantityRule';
import customerTotalOrdersRule from './ruleEditors/customerTotalOrdersRule';
import customerRegistrationDateRule from './ruleEditors/customerRegistrationDateRule';
import promotionRedemptionCountRule from './ruleEditors/promotionRedemptionCountRule';
import productBasePriceTotalRule from './ruleEditors/productBasePriceTotalRule';

export default function RuleEditor(selector) {
    let ruleData = [];
    let ruleTypes = [];
    var groupComparers;
    let comparers = [];
    let operators = [];
    let deliveryMethods = [];

    function renderGroup(rule) {

        var groupDef = groupComparers.find(function (f) {
            return f.id == rule.entity.groupOperator;
        });
        var groupOperatorHtml = `<div class="group-operator"><div class="text">${groupDef.name}</div><div class="sep"></div></div>`;

        return `<div class="js-rule js-rule-group rule-group row" data-rule-id="${rule.id}">
                <div class="rule-group-marker">
                    <div class="dropdown btn-rule-options">
                      <button class="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-caret-down"></i>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="js-rule-group-btn" href="">Add Group</a>
                        <a class="js-rule-btn" href="">Add Rule</a>
                        <a class="js-rule-delete-btn" href="#">Delete Group</a>
                        <a class="js-rule-switch" href="#">Switch to '${rule.entity.groupOperator == 1 ? 'AND' : 'OR'}' Operator</a>
                      </div>
                    </div>
                </div>
               
                    <div class="row">
                    ${rule.rules.length == 0 ? '<div class="no-rules">No Rules</div>' : rule.rules.map((o) => renderRuleBlock(o)).join(groupOperatorHtml)}

                    </div>
                </div>`;
    }

    function renderRule(rule) {
        var ruleDef = ruleTypes.find(r => r.type == rule.entity.ruleName);

        var html = ``;
        if (ruleDef == undefined) {
            html = `<div class='js-rule-target col-md-2'>
                            ${createRuleTarget(ruleTypes, rule)}
                    </div>`;
        } else {
            switch (ruleDef.type) {
                case "OrderSubtotalRule":
                    var ruleEditor = orderSubtotalRule(ruleTypes, operators, comparers);
                    html = ruleEditor.renderRule(rule);
                    break;
                case "PromotionCodeEqualityRule":
                    var ruleEditor = promotionCodeEqualityRule(ruleTypes, operators, comparers);
                    html = ruleEditor.renderRule(rule);
                    break;
                case "ProductContainedInRule":
                    var ruleEditor = productContainedInRule(ruleTypes, operators, comparers);
                    html = ruleEditor.renderRule(rule);
                    break;
                case "ProductGroupContainedInRule":
                    var ruleEditor = productGroupContainedInRule(ruleTypes, operators, comparers);
                    html = ruleEditor.renderRule(rule);
                    break;
                case "ProductTypeRule":
                    var ruleEditor = productTypeRule(ruleTypes, operators, comparers);
                    html = ruleEditor.renderRule(rule);
                    break;
                case "CustomerEqualityRule":
                    var ruleEditor = customerEqualityRule(ruleTypes, operators, comparers);
                    html = ruleEditor.renderRule(rule);
                    break;
                case "DatePromotionRule":
                    var ruleEditor = datePromotionRule(ruleTypes, operators, comparers);
                    html = ruleEditor.renderRule(rule);
                    break;
                case "DeliveryCodeEqualityRule":
                    var ruleEditor = deliveryCodeEqualityRule(ruleTypes, operators, comparers, deliveryMethods);
                    html = ruleEditor.renderRule(rule);
                    break;
                case "ProductQuantityRule":
                    var ruleEditor = productQuantityRule(ruleTypes, operators, comparers, deliveryMethods);
                    html = ruleEditor.renderRule(rule);
                    break;
                case "CustomerTotalOrdersRule":
                    var ruleEditor = customerTotalOrdersRule(ruleTypes, operators, comparers, deliveryMethods);
                    html = ruleEditor.renderRule(rule);
                    break;
                case "CustomerRegistrationDateRule":
                    var ruleEditor = customerRegistrationDateRule(ruleTypes, operators, comparers, deliveryMethods);
                    html = ruleEditor.renderRule(rule);
                    break;
                case "PromotionRedemptionCountRule":
                    var ruleEditor = promotionRedemptionCountRule(ruleTypes, operators, comparers, deliveryMethods);
                    html = ruleEditor.renderRule(rule);
                    break;
                case "ProductBasePriceTotalRule":
                    var ruleEditor = productBasePriceTotalRule(ruleTypes, operators, comparers, deliveryMethods);
                    html = ruleEditor.renderRule(rule);
                    break;
                    
            }
        }

        return `<div class="js-rule rule row" data-rule-id="${rule.id}">
                    ${html}
                    <div class="rule-edit"><a href="" class="js-rule-edit"><i class="fa fa-edit"></i></a></div>
                    <div class="rule-delete"><a href="" class="js-rule-delete"><i class="fa fa-times"></i></a></div>
                </div>`;
    }

    function editRule(rule, container) {


        var ruleDef = ruleTypes.find(function (f) {
            return f.type == rule.entity.ruleName;
        });

        var html = ``;
        if (ruleDef == undefined) {
            html = `<div class='js-rule-target col-md-2'>
                            ${createRuleTarget(ruleTypes, rule)}
                    </div>`;
        } else {
            switch (ruleDef.type) {
                case "OrderSubtotalRule":
                    var ruleEditor = orderSubtotalRule(ruleTypes, operators, comparers);
                    html = ruleEditor.renderEditor(rule);
                    break;
                case "PromotionCodeEqualityRule":
                    var ruleEditor = promotionCodeEqualityRule(ruleTypes, operators, comparers);
                    html = ruleEditor.renderEditor(rule);
                    break;
                case "ProductContainedInRule":
                    var ruleEditor = productContainedInRule(ruleTypes, operators, comparers);
                    html = ruleEditor.renderEditor(rule);
                    break;
                case "ProductGroupContainedInRule":
                    var ruleEditor = productGroupContainedInRule(ruleTypes, operators, comparers);
                    html = ruleEditor.renderEditor(rule);
                    break;
                case "ProductTypeRule":
                    var ruleEditor = productTypeRule(ruleTypes, operators, comparers);
                    html = ruleEditor.renderEditor(rule);
                    break;
                case "CustomerEqualityRule":
                    var ruleEditor = customerEqualityRule(ruleTypes, operators, comparers);
                    html = ruleEditor.renderEditor(rule);
                    break;
                case "DatePromotionRule":
                    var ruleEditor = datePromotionRule(ruleTypes, operators, comparers);
                    html = ruleEditor.renderEditor(rule);
                    break;
                case "DeliveryCodeEqualityRule":
                    var ruleEditor = deliveryCodeEqualityRule(ruleTypes, operators, comparers, deliveryMethods);
                    html = ruleEditor.renderEditor(rule);
                    break; 
                case "ProductQuantityRule":
                    var ruleEditor = productQuantityRule(ruleTypes, operators, comparers, deliveryMethods);
                    html = ruleEditor.renderEditor(rule);
                    break;
                case "CustomerTotalOrdersRule":
                    var ruleEditor = customerTotalOrdersRule(ruleTypes, operators, comparers, deliveryMethods);
                    html = ruleEditor.renderEditor(rule);
                    break;
                case "CustomerRegistrationDateRule":
                    var ruleEditor = customerRegistrationDateRule(ruleTypes, operators, comparers, deliveryMethods);
                    html = ruleEditor.renderEditor(rule);
                    break;
                case "PromotionRedemptionCountRule":
                    var ruleEditor = promotionRedemptionCountRule(ruleTypes, operators, comparers, deliveryMethods);
                    html = ruleEditor.renderEditor(rule);
                    break;
                case "ProductBasePriceTotalRule":
                    var ruleEditor = productBasePriceTotalRule(ruleTypes, operators, comparers, deliveryMethods);
                    html = ruleEditor.renderEditor(rule);
                    break;     
            }
        }

        container.html(html);
        container.addClass('js-rule-editing');
        if (container.find('.datepicker')) {
            container.find('.datepicker').datepicker({ dateFormat: 'dd/mm/yy' });
        }

        if (container.find('.selectize')) {
            $('.selectize').selectize({ create: true });
        }
        $('body').addClass('editing');
    }

    function validateRule(rule, container) {
        var ruleDef = ruleTypes.find(r => r.type == rule.entity.ruleName);
        switch (ruleDef.type) {
            case "OrderSubtotalRule":
                var ruleEditor = orderSubtotalRule(ruleTypes, operators, comparers);
                return ruleEditor.validateRule(rule, container);
            case "PromotionCodeEqualityRule":
                var ruleEditor = promotionCodeEqualityRule(ruleTypes, operators, comparers);
                return ruleEditor.validateRule(rule, container);
            case "ProductContainedInRule":
                var ruleEditor = productContainedInRule(ruleTypes, operators, comparers);
                return ruleEditor.validateRule(rule, container);
            case "ProductGroupContainedInRule":
                var ruleEditor = productGroupContainedInRule(ruleTypes, operators, comparers);
                return ruleEditor.validateRule(rule, container);
            case "ProductTypeRule":
                var ruleEditor = productTypeRule(ruleTypes, operators, comparers);
                return ruleEditor.validateRule(rule, container);
            case "CustomerEqualityRule":
                var ruleEditor = customerEqualityRule(ruleTypes, operators, comparers);
                return ruleEditor.validateRule(rule, container);
            case "DatePromotionRule":
                var ruleEditor = datePromotionRule(ruleTypes, operators, comparers);
                return ruleEditor.validateRule(rule, container);
            case "DeliveryCodeEqualityRule":
                var ruleEditor = deliveryCodeEqualityRule(ruleTypes, operators, comparers, deliveryMethods);
                return ruleEditor.validateRule(rule, container); 
            case "ProductQuantityRule":
                var ruleEditor = productQuantityRule(ruleTypes, operators, comparers, deliveryMethods);
                return ruleEditor.validateRule(rule, container);
            case "CustomerTotalOrdersRule":
                var ruleEditor = customerTotalOrdersRule(ruleTypes, operators, comparers, deliveryMethods);
                return ruleEditor.validateRule(rule, container);
            case "CustomerRegistrationDateRule":
                var ruleEditor = customerRegistrationDateRule(ruleTypes, operators, comparers, deliveryMethods);
                return ruleEditor.validateRule(rule, container);
            case "PromotionRedemptionCountRule":
                var ruleEditor = promotionRedemptionCountRule(ruleTypes, operators, comparers, deliveryMethods);
                return ruleEditor.validateRule(rule, container);
            case "ProductBasePriceTotalRule":
                var ruleEditor = productBasePriceTotalRule(ruleTypes, operators, comparers, deliveryMethods);
                return ruleEditor.validateRule(rule, container);
                
        }
        return rule;
    }

    function renderRuleBlock(rule) {
        if (rule.type == 'group') {
            return renderGroup(rule);
        }
        if (rule.type == 'rule') {
            return renderRule(rule);
        }
    }

    function renderEditor(rules) {
        var groupOperatorHtml = `<div class="group-operator"><div class="text">And</div><div class="sep"></div></div>`;

        var html = `
            <div class="rule-editor">
                <h3>Rules (Optional)</h3>
                <small><i>- This promotion is valid if all rule pass.</i></small>
                <div class="row rule-table-header">
                    <div class="col-md-2">Variable</div>
                    <div class="col-md-2">Operator</div>
                    <div class="col-md-2">Comparison</div>
                    <div class="col-md-3">Value</div>
                    <div class="col-md-2">Message</div>
                    <div class="col-md-1"></div>
                </div>
                <div class="js-promotion-rules promotion-rules">
                ${rules.map((o) => renderRuleBlock(o)).join(groupOperatorHtml)}
               
                </div>
            </div>`;

        var noGroupsHtml = `<a class="js-rule-group-btn" href="">Add Group</a>`

        return rules.length > 0 ? html : noGroupsHtml;
    }

    function bind(rules) {
        ruleData = rules;
        $.when(
            $.ajax('/api/rules/ruleTypes/scope'),
            $.ajax('/api/rules/groupcomparers'),
            $.ajax('/api/rules/comparers'),
            $.ajax('/api/rules/operators'),
            $.ajax('/api/rules/deliverymethods'))
            .then(function (a, b, c, d, e) {
                ruleTypes = a[0];
                groupComparers = b[0];
                comparers = c[0];
                operators = d[0];
                deliveryMethods = e[0];
                $(selector).html(renderEditor(ruleData));
            });

    };

    function addGroup(group, parentId) {
        var rule = {
            id: '0',
            type: 'group',
            entity: group,
            rules: []
        };

        var r = [];
        ruleData.forEach(function (a) {
            r.push(recurseRules(a, parentId, rule))
        });
        if (r.length == 0) {
            r.push(rule);
        }
        return r;
    }

    function addRule(rule, parentId) {
        var rule = {
            id: '0',
            type: 'rule',
            entity: rule,
            rules: []
        };

        var r = [];
        ruleData.forEach(function (a) {
            r.push(recurseRules(a, parentId, rule))
        });
        if (r.length == 0) {
            r.push(rule);
        }
        return r;
    }

    function updateRule(rule, parentId) {
        var r = [];
        ruleData.forEach(function (a) {
            r.push(recurseUpdateRule(a, parentId, rule))
        });
        if (r.length == 0) {
            r.push(rule);
        }
        ruleData = r;
    }

    function getRule(parentId) {
        var t = ruleData;
        var item;
        t.forEach(function (a) {
            let b = findNodeInTree(a, parentId);
            if (b != null) {
                item = b;
            }
        });
        return item;
    }

    function deleteRule(id) {
        var r = [];
        ruleData.forEach(function (a) {
            r.push(recurseDeleteRules(a, id))
        });
        if (r.length == 0) {
            r.push(rule);
        }
        ruleData = r;
    }

  

    function getData() {
        return ruleData;
    }


    function bindEvents(selector) {
        $(document).on('click', selector + ' .js-rule-group-btn', function (e) {
            e.preventDefault();
            var group = {
                groupOperator: 0
            };
            var parent = $(this).closest('[data-rule-id]');
            var parentId = parent.length ? parent.attr('data-rule-id') : '-1';
            ruleData = addGroup(group, parentId);
            $(selector).html(renderEditor(ruleData));
        });
        $(document).on('click', selector + ' .js-rule-btn', function (e) {
            e.preventDefault();
            var rule = {
                ruleName: "",
                operator: 0,
                comparer: 1,
                valueType: "",
                errorMessage: null,
                value: null
            };
            var parent = $(this).closest('[data-rule-id]');
            var parentId = parent.length ? parent.attr('data-rule-id') : '-1';
            ruleData = addRule(rule, parentId);
            $(selector).html(renderEditor(ruleData));
            var newRuleContainer = $('[data-rule-id="' + parentId + '"]').find('.js-rule').last();
            var id = newRuleContainer.attr('data-rule-id');
            var newRule = getRule(id);
            editRule(newRule, newRuleContainer);

        });
        $(document).on('click', selector + ' .js-rule-delete-btn', function (e) {
            e.preventDefault();
            var container = $(this).closest('[data-rule-id]');
            deleteRule(container.attr('data-rule-id'));
            $(selector).html(renderEditor(ruleData));
        });
        $(document).on('click', selector + ' .js-rule-edit', function (e) {
            e.preventDefault();
            var container = $(this).closest('[data-rule-id]');
            var id = container.attr('data-rule-id');
            var rule = getRule(id);
            editRule(rule, container);
        });
        $(document).on('click', selector + ' .js-rule-delete', function (e) {
            e.preventDefault();
            var container = $(this).closest('[data-rule-id]');
            if (confirm('Are you sure?')) {
                deleteRule(container.attr('data-rule-id'));
                $(selector).html(renderEditor(ruleData));
            }
        });
        $(document).on('click', selector + ' .js-rule-switch', function (e) {
            e.preventDefault();
            var id = $(this).closest('[data-rule-id]').attr('data-rule-id');
            var rule = getRule(id);
            rule.entity.groupOperator = rule.entity.groupOperator == 1 ? 0 : 1;
            updateRule(rule, id);
            $(selector).html(renderEditor(ruleData));
        });
        $(document).on('change', selector + ' .js-rule-target select', function (e) {
            e.preventDefault();
            var container = $(this).closest('[data-rule-id]');
            var id = container.attr('data-rule-id');
            var rule = getRule(id);
            rule.def = {
                rule: ruleTypes.find(r => r.type == rule.entity.ruleName),
                ops: operators.find(r => r.id == rule.entity.operator),
                comps: comparers.find(r => r.id == rule.entity.comparer)
            };
            rule.entity.ruleName = $(this).val();
            rule.entity.operator = null;
            rule.entity.comparer = null;
            editRule(rule, container);
        });
        $(document).on('change', selector + ' .js-rule-comparer select', function (e) {
            e.preventDefault();
            var container = $(this).closest('[data-rule-id]');
            var id = container.attr('data-rule-id');
            var rule = getRule(id);
            rule.entity.ruleName = container.find('.js-rule-target select').val();
            rule.entity.operator = container.find('.js-rule-operator select').val();
            rule.entity.comparer = $(this).val();
            editRule(rule, container);
        });
        $(document).on('click', selector + ' .js-rule-edit-done', function (e) {
            e.preventDefault();
            var container = $(this).closest('[data-rule-id]');
            var id = container.attr('data-rule-id');
            var rule = getRule(id);

            var updatedRule = validateRule(rule, container);
            if (!container.hasClass("error")) {
                updateRule(updatedRule, id);
                $(selector).html(renderEditor(ruleData));
            }
            $('body').removeClass('editing');
        });

    }
 

    bindEvents(selector);
    return {
        bind, getData
    };
}






export function buildEditor(rules) {
    var r = [];
    rules.forEach(function (a) {
        r.push(recurseRules(a, parentId, rule))
    });
    if (r.length == 0) {
        r.push(rule);
    }
    return r;
}

function recurseRules(rule, id, node) {
    if (rule.id == id) {
        node.id = `${new Date().getTime()}${Math.random()}`;
        rule.rules.push(node);
        return rule;
    }

    var newRuleSet = [];
    rule.rules.forEach(function (r) {
        newRuleSet.push(recurseRules(r, id, node));
    });
    rule.rules = newRuleSet;
    return rule;
}

function recurseDeleteRules(rule, id) {
    var r = rule.rules.filter(x => x.id != id);

    var newRuleSet = [];
    var idx = 0;
    r.forEach(function (r) {
        newRuleSet.push(recurseDeleteRules(r, id));
    });
    rule.rules = newRuleSet;
    return rule;
}


const findNodeInTree = (tree, key) => {
    let result = null;
    if (tree.id == key) {
        result = tree;
    } else if (tree.rules != undefined) {
        tree.rules.forEach(node => {
            if (result == null) {
                if (node.id === key) {
                    result = node;
                } else if (node.rules) {
                    result = findNodeInTree(node, key);
                }
            }
        });
    }
    return result;
}

function recurseUpdateRule(rule, id, node) {
    if (rule.id == id) {
        rule = node;
        return rule;
    }

    var newRuleSet = [];
    rule.rules.forEach(function (r) {
        newRuleSet.push(recurseUpdateRule(r, id, node));
    });
    rule.rules = newRuleSet;
    return rule;
}




function renderProductGroupPicker() {
    return `
        <select class="selectize" multiple>
            <option value="1">Standard</option>
            <option value="2">Moulding</option>
            <option value="3">Mountboard</option>
            <option value="4">Fineprint</option>
        </select>
    `;
}

