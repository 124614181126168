

export function uploadToMediaLibrary(element, path, callbackSuccess, callbackError, persist = true) {
    var data = new FormData();
    for (var x = 0; x < element.files.length; x++) {
        data.append('image[file]', element.files[x]);
    }

    $.ajax({
        url: '/api/file?path=' + path + "&persist=" + persist,
        type: 'POST',
        data: data,
        processData: false, 
        contentType: false, 
        success: function (res) {
            if (callbackSuccess != undefined && typeof callbackSuccess == "function") {
                setTimeout(function () { callbackSuccess(res);}, 500); // Azure seems to need a moment to make the image available, so this is an enforced delay
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (callbackSuccess != undefined && typeof callbackSuccess == "function") {
                if (jqXHR.responseText) {
                    errors = JSON.parse(jqXHR.responseText).errors
                    callbackError(errors, jqXHR);
                }
            }         
        }
    });
}
