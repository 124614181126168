import 'bootstrap';

export default function tinyAlert(message, success) {
    if (success) {
        $.smallBox({
            title: "Success",
            content: message,
            color: "#659265",
            iconSmall: "fa fa-check fa-2x fadeInRight animated",
            timeout: 4000
        });
    } else {
        $.smallBox({
            title: "Error",
            content: message,
            color: "#C46A69",
            iconSmall: "fa fa-times fa-2x fadeInRight animated",
            timeout: 4000
        });
    }
}