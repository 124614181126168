
export function createRuleOperator(operators, rule, ruleDef) {
    if (rule == null || rule == undefined || ruleDef == undefined) return '';
    var availableOperators = operators.filter(function (c) {
        return ruleDef.operators.includes(c.id);
    });

    return `<select class="form-control">
                    ${availableOperators.map(i => `<option value='${i.id}' ${rule != undefined && rule != null && i.id == rule.entity.operator ? 'selected' : ''}>${i.name}</option>`).join('')}
                </select>`;
}

export function createRuleTarget(ruleTypes, rule) {
    return `<select class="form-control">
                    <option value="">Select a target</option>
                    ${ruleTypes.map(i => `<option value='${i.type}' ${rule != undefined && rule != null && i.type == rule.entity.ruleName ? 'selected' : ''}>${i.ruleName}</option>`).join('')}
                </select>`;
}

export function createRuleComparer(comparers, rule, ruleDef) {
    if (ruleDef == null || ruleDef == undefined) return '';
    var availableComparers = comparers.filter(function (c) {
        return ruleDef.comparers.includes(c.id);
    });

    return `<select class="form-control">
                    ${availableComparers.map(i => `<option value='${i.id}' ${rule != undefined && rule != null && i.id == rule.entity.comparer ? 'selected' : ''}>${i.name}</option>`).join('')}
                </select>`;
}

export function createPercentageInput(rule) {
    return `<div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Percentage" aria-label="Username" aria-describedby="basic-addon1" value="${rule.entity != undefined ? rule.entity.value : ''}">
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
            </div>`;
}

export function createCurrencyInput(rule) {
    return `<div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">£</span>
              </div>
              <input type="text" class="form-control" placeholder="Percentage" aria-label="Username" aria-describedby="basic-addon1" value="${rule.entity != undefined ? rule.entity.value : ''}">
            </div>`;
}

export function createProductTypeInput(rule) {
    return `
        <select class="selectize" multiple>
            <option value="1" ${rule.entity != undefined && rule.entity.value == "1" ? 'selected' : ''}>Standard</option>
            <option value="2" ${rule.entity != undefined && rule.entity.value == "2" ? 'selected' : ''}>Moulding</option>
            <option value="3" ${rule.entity != undefined && rule.entity.value == "3" ? 'selected' : ''}>Mountboard</option>
            <option value="4" ${rule.entity != undefined && rule.entity.value == "4" ? 'selected' : ''}>Fineprint</option>
        </select>
    `;
}

export function renderProductGroupPicker(rule) {
    var val = rule.entity != undefined && rule.entity.value != undefined && Array.isArray(rule.entity.value) ? rule.entity.value : [];

    var val = [];
    if (rule.entity != undefined && rule.entity.value != undefined && Array.isArray(rule.entity.value)) {
        rule.entity.value.forEach(function (f) {
            val.push(parseInt(f));
        });
    }

    return `
        <select class="selectize" multiple>
            <option value="1" ${val.includes(1) ? 'selected' : ''}>Standard</option>
            <option value="2" ${val.includes(2) ? 'selected' : ''}>Moulding</option>
            <option value="3" ${val.includes(3) ? 'selected' : ''}>Mountboard</option>
            <option value="4" ${val.includes(4) ? 'selected' : ''}>Fineprint</option>
            <option value="5" ${val.includes(5) ? 'selected' : ''}>Chevron</option>
            <option value="6" ${val.includes(6) ? 'selected' : ''}>StyreneSheet</option>
            <option value="7" ${val.includes(7) ? 'selected' : ''}>Chop</option>
        </select>
    `;
}

export function renderDeliveryMethodPicker(rule, deliveryMethods, multiple) {
    var val = rule.entity != undefined && rule.entity.value != undefined && Array.isArray(rule.entity.value) ? rule.entity.value : [];

    var val = [];
    if (rule.entity != undefined && rule.entity.value != undefined && Array.isArray(rule.entity.value)) {
        rule.entity.value.forEach(function (f) {
            val.push(parseInt(f));
        });
    }

    var i = [];

    deliveryMethods.forEach(function (o) {
        if (val.includes(o.id)) {
            i.push(`<option value="${o.id}" selected>${o.name}</option>`);
        } else {
            i.push(`<option value="${o.id}">${o.name}</option>`);
        }
    });


    return `
            <select class="selectize" ${multiple ? 'multiple' : ''}>
                ${i.join('')}         
            </select>`;
}