import 'bootstrap';
import tinyAlert from './../core/tinyAlert';

export function initCustomers() {
    $(document).on('click', '#CustomersForm button[type=submit]', function (e) {
        DoCustomerSearch();
    });

    $(document).on('keydown', '#CustomersForm .search', function (e) {
        if (e.keyCode == 13) {
            DoCustomerSearch();
        }
    });

    $(document).on('click', '.reorder', function (e) {
        e.preventDefault();
        $('#SortField').val($(this).data('sortfield'));
        $('#SortDirection').val($(this).data('sortdirection'));
        DoCustomerSearch();
    });

    $(document).on('click', '#CustomersPagination li a', function (e) {
        e.preventDefault();
        $('#CurrentPage').val(parseInt($(this).data('pageid')));
        DoCustomerSearch();
    });
    $(document).on('click', '#CustomerOrderPagination li a', function (e) {
        e.preventDefault();
        var currentPage = parseInt($(this).html());
        DoOrdersSearch(currentPage);
    });

    $(document).on('click', '#SaveCustomer', function (e) {
        e.preventDefault();
        var data = $('#UpdateCustomer').serialize();
        var that = $(this);
        var buttonText = that.html();
        $.post('/customers/update', data, function (data) {
            tinyAlert(data.message, data.status == "ok");
            that.html(buttonText);
        });
    });

    $(document).on('click', '#QuickAddAddress .btn-success', function (e) {
        e.preventDefault();
        var data = $('#QuickAddAddress form').serialize();
        $.post('/customers/addaddress', data, function (data) {
            tinyAlert(data.message, data.status == "ok");
            $('#QuickAddAddress .close').trigger('click');
            $.get('/customers/addresses/' + data.item.CustomerId, function (html) {
                $('#customeraddresses').html(html);
            });
        });
    });

    $(document).on('submit', '#QuickAddPaymentForm', function (e) {
        e.preventDefault();
        addCustomerPayment();
    });

    $(document).on('click', '.deletepayment', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        deleteCustomerPayment(id);
    });
    
}



function DoOrdersSearch(currentPage) {
    var sortField = $('#SortField').val();
    var sortDirection = $('#SortDirection').val();
    var pageSize = $('#PageSize').val();
    var id = $('#CustomerId').val();
    $.ajax({
        url: "/Customers/orders",
        data: {
            customerId: id, sortField: sortField, reverse: sortDirection, currentPage: currentPage, pageSize: pageSize
        },
        success: function (data) {
            $('#OrderResults').hide().html(data).fadeIn();
        },
        cache: false
    });
}


function DoCustomerSearch() {
    var search = $('#search').val();
    var sortField = $('#SortField').val();
    var sortDirection = $('#SortDirection').val();
    var pageSize = $('#PageSize').val();
    var currentPage = $('#CurrentPage').val();

    $.ajax({
        url: "/Customers/Find",
        data: {
            search: search, sortField: sortField, reverse: sortDirection, currentPage: currentPage, pageSize: pageSize
        },
        success: function (data) {
            $('#CustomerResults').hide().html(data).fadeIn();
        },
        cache: false
    });
}

function addCustomerPayment() {
    if ($('#QuickAddPayment form').valid()) {
        var that = $('#QuickAddPayment form').find('button.btn-success');
        var data = $('#QuickAddPayment form').serialize();
        console.log(data);
        var buttonText = that.html();
        that.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span>Saving');
        $.ajax({
            url: '/customers/addpayment',
            type: 'POST',
            data: data,
            processData: false,
            success: function (data) {
                that.html(buttonText);
                if (data.status == "ok") {
                    tinyAlert(data.message, data.status == "ok");
                    $('#QuickAddPayment .close').trigger('click');
                    $.get('/customers/payments/' + $('#CustomerId').val(), function (html) {
                        $('#payments').html(html);
                    });

                } else {
                    tinyAlert(data.message, data.status == "ok");
                }


            }
        });
    }
}


function deleteCustomerPayment(id) {
    if (confirm('Are you sure you want to delete this payment request?')) {
        $.ajax({
            url: '/customers/deletepayment?id=' + id,
            type: 'POST',
            processData: false,
            success: function (data) {
                if (data.status == "ok") {
                    tinyAlert(data.message, data.status == "ok");
                    $('#QuickAddPayment .close').trigger('click');
                    $.get('/customers/payments/' + $('#CustomerId').val(), function (html) {
                        $('#payments').html(html);
                    });

                } else {
                    tinyAlert(data.message, data.status == "ok");
                }
            }
        });
    }
  
}