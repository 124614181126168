import 'bootstrap';
import tinyAlert from './../core/tinyAlert';

export function initDiscounts() {
    $("#ProductsIncludedLookup").autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/products/search",
                data: {
                    search: request.term
                },
                success: function (data) {
                    response($.map(data, function (item) {
                        return {
                            label: '[' + item.Code + '] ' + item.Name,
                            value: item.Code,
                            name: item.Name,
                            id: item.Id
                        };
                    }));
                }
            });
        },
        appendTo: "#ProductsIncludedTable",
        minLength: 3,
        select: function (event, ui) {
            if (ui.item) {
                if (!$('#ProductsIncludedTable tr[data-id=' + ui.item.value + ']').length) {
                    $('#ProductsIncludedTable tbody').append('<tr data-id="' + ui.item.value + '"><td>' + ui.item.value + '</td><td>' + ui.item.name + '</td><td><a href="#" class="discount-remove-row">Remove</a></td></tr>');
                }
                if ($('#ProductsExcludedTable tr[data-id=' + ui.item.value + ']').length) {
                    $('#ProductsExcludedTable tr[data-id=' + ui.item.value + ']').remove();
                }
            }
        },
        open: function () {
            $(this).removeClass("ui-corner-all").addClass("ui-corner-top");
        },
        close: function () {
            $(this).removeClass("ui-corner-top").addClass("ui-corner-all");
        }
    });
    $("#ProductsExcludedLookup").autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/products/search",
                data: {
                    search: request.term
                },
                success: function (data) {
                    response($.map(data, function (item) {
                        return {
                            label: '[' + item.Code + '] ' + item.Name,
                            value: item.Code,
                            name: item.Name,
                            id: item.Id
                        };
                    }));
                }
            });
        },
        appendTo: "#ProductsExcludedTable",
        minLength: 3,
        select: function (event, ui) {
            if (ui.item) {
                if (!$('#ProductsExcludedTable tr[data-id=' + ui.item.value + ']').length) {
                    $('#ProductsExcludedTable tbody').append('<tr data-id="' + ui.item.value + '"><td>' + ui.item.value + '</td><td>' + ui.item.name + '</td><td><a href="#" class="discount-remove-row">Remove</a></td></tr>');
                }
                if ($('#ProductsIncludedTable tr[data-id=' + ui.item.value + ']').length) {
                    $('#ProductsIncludedTable tr[data-id=' + ui.item.value + ']').remove();
                }
            }
        },
        open: function () {
            $(this).removeClass("ui-corner-all").addClass("ui-corner-top");
        },
        close: function () {
            $(this).removeClass("ui-corner-top").addClass("ui-corner-all");
        }
    });
    $("#CustomerLookup").autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/customers/search",
                data: {
                    search: request.term
                },
                success: function (data) {
                    response($.map(data, function (item) {
                        console.log(item);
                        return {
                            label: item.Id,
                            value: item.Id,
                            name: item.Id,
                            id: item.Id
                        };
                    }));
                }
            });
        },
        appendTo: "#CustomerTable",
        minLength: 3,
        select: function (event, ui) {
            console.log(ui.item);
            if (ui.item) {
                if (!$('#CustomerTable tr[data-id=' + ui.item.value + ']').length) {
                    $('#CustomerTable tbody').append('<tr data-id="' + ui.item.id + '"><td>' + ui.item.name + '</td><td><input type="number" /></td><td><a href="#" class="discount-remove-row">Remove</a></td></tr>');
                }
            }
        },
        open: function () {
            $(this).removeClass("ui-corner-all").addClass("ui-corner-top");
        },
        close: function () {
            $(this).removeClass("ui-corner-top").addClass("ui-corner-all");
        }
    });
    $(document).on('change', '#DiscountType', function (e) {
        e.preventDefault();
        var basketTab = $('#basketRulesTab');
        var deliveryTab = $('#deliveryRulesTab');
        var productGroupTab = $('#productGroupRulesTab');
        var productTab = $('#productRulesTab');

        switch ($(this).prop('selectedIndex')) {
            case 0: // Basket
                productTab.hide();
                productGroupTab.hide();
                deliveryTab.hide();
                break;
            case 1: // Product
                productTab.show();
                productGroupTab.show();
                deliveryTab.hide();
                break;
            case 2: // Delivery
                productTab.hide();
                productGroupTab.hide();
                deliveryTab.show();
                break;
        }
    });

    $(document).on('change', '#DiscountValueType', function (e) {
        e.preventDefault();
        var valueLabel = $('#DiscountValueLabelAmount');
        var percentageLabel = $('#DiscountValueLabelPercentage');

        switch ($(this).prop('selectedIndex')) {
            case 0: // Percentage
                valueLabel.hide();
                percentageLabel.show();
                break;
            case 1: // Price Off
                valueLabel.show();
                percentageLabel.hide();
                break;
        }
    });

    $(document).on('click', '.discount-remove-row', function (e) {
        e.preventDefault();
        $(this).parent().parent().remove();
    });
    $('#Discount').validate();
    $(document).on('click', '#SaveDiscount', function (e) {
        e.preventDefault();
        SaveDelivery();
    });

    $(document).on('click', '.included', function (e) {
        $(this).parent().parent().find('.excluded').prop('checked', false);
    });

    $(document).on('click', '.excluded', function (e) {
        $(this).parent().parent().find('.included').prop('checked', false);
    });

    $(document).on('change', '#UploadDiscountProductsIncluded', function (e) {
        FindProductsForDiscounts($(this), '#ProductsIncludedTable', '#ProductsExcludedTable');
    });

    $(document).on('change', '#UploadDiscountProductsExcluded', function (e) {
        FindProductsForDiscounts($(this), '#ProductsExcludedTable', '#ProductsIncludedTable');
    });

    function FindProductsForDiscounts(item, targetTable, targetTableReverse) {
        var data = new FormData();
        for (var x = 0; x < item[0].files.length; x++) {
            data.append('file[file]', item[0].files[x]);
        }
        $.ajax({
            url: '/discounts/FindProducts',
            type: 'POST',
            data: data,
            processData: false, 
            contentType: false, 
            success: function (res) {
                $.each(res.products, function (i, v) {
         
                    if (!$(targetTable + ' tr[data-id=' + v.code + ']').length) {
                        $(targetTable + '  tbody').append('<tr data-id="' + v.code + '"><td>' + v.code + '</td><td>' + v.name + '</td><td><a href="#" class="discount-remove-row">Remove</a></td></tr>');
                    }
                    if ($(targetTableReverse + ' tr[data-id=' + v.code + ']').length) {
                        $(targetTableReverse + ' tr[data-id=' + v.code + ']').remove();
                    }
                });
                console.log(res);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (jqXHR.responseText) {
                    errors = JSON.parse(jqXHR.responseText).errors
                }
            }
        });
    }


    function SaveDelivery() {
        var discountCode = {
            discountId: $('#DiscountId').val(),
            discountType: $('#DiscountType').val(),
            code: $('#DiscountCode').val(),
            discountName: $('#DiscountName').val(),
            discountValueType: $('#DiscountValueType').val(),
            discountValue: $('#DiscountValue').val(),
            disabled: $('#DiscountValue').is(':checked'),
            minimumOrderValue: $('#MinimumOrderValue').val(),
            validFrom: null,
            validUntil: null,
            defaultCustomerMaximumUsage: $('#DefaultCustomerMaximumUsage').val(),
            overrideOtherDiscounts: true,
            customerRules: [],
            productsIncluded: [],
            productsExcluded: [],
            productGroupsIncluded: [],
            productGroupsExcluded: [],
            deliveryOptionsIncluded: [],
            deliveryOptionsExcluded: []
        };

        if ($('#ValidFrom').val() != '') {
            var validFrom = $("#ValidFrom").datepicker("getDate");
            discountCode.validFrom = validFrom.toISOString().split('T')[0];
        }
        if ($('#ValidUntil').val() != '') {
            var validTo = $("#ValidUntil").datepicker("getDate");
            discountCode.validUntil = validTo.toISOString().split('T')[0];
        }

        $('#CustomerTable tbody tr').each(function () {
            var accountCode = $(this).attr('data-id');
            var maxUsage = $(this).find('input').val();
            discountCode.customerRules.push({ accountCode: accountCode, maxUsage: maxUsage });
        });

        if (discountCode.discountType == 1) {
            $('#ProductsIncludedTable tbody tr').each(function () {
                var productCode = $(this).attr('data-id');
                discountCode.productsIncluded.push({ productCode: productCode });
            });

            $('#ProductsExcludedTable tbody tr').each(function () {
                var productCode = $(this).attr('data-id');
                discountCode.productsExcluded.push({ productCode: productCode });
            });

            $('#ProductGroupsTable tbody tr').each(function () {
                var productGroup = $(this).attr('data-id');
                var included = $(this).find('.included').is(':checked');
                var excluded = $(this).find('.excluded').is(':checked');
                if (included) {
                    discountCode.productGroupsIncluded.push({ productGroup: productGroup });
                } else if (excluded) {
                    discountCode.productGroupsExcluded.push({ productGroup: productGroup });
                }
            });
        }
        if (discountCode.discountType == 2) {
            $('#DeliveryMethodsIncludedTable tbody tr').each(function () {
                var deliveryMethodId = $(this).attr('data-id');
                var included = $(this).find('.included').is(':checked');
                var excluded = $(this).find('.excluded').is(':checked');
                if (included) {
                    discountCode.deliveryOptionsIncluded.push({ deliveryMethodId: deliveryMethodId });
                } else if (excluded) {
                    discountCode.deliveryOptionsExcluded.push({ deliveryMethodId: deliveryMethodId });
                }
            });
        }
        console.log(discountCode);

        $.ajax
            ({
                type: "POST",
                url: '/discounts/save',
              //  contentType: "application/json; charset=utf-8",
   //             dataType: "json",
                async: false,
                data: JSON.stringify(discountCode),
                success: function (data) {
                    if (data.status == "ok") {
                        tinyAlert("Saved", true);
                        if (data.saveType == "0")
                            window.location.href = '/discounts/get?id=' + data.id;
                    } else {
                        tinyAlert(data.message, false);
                    }
                }
            })
    }
}


//    $(document).on('click', '#AddAccountRuleButton', function (e) {
//        e.preventDefault();
//        clearModal('#AccountRulesModal');
//        $('#AccountRulesModal').modal('show');
//    });

//    $(document).on('click', '.edit-charge', function (e) {
//        e.preventDefault();
//        var id = $(this).attr('data-id');
//        var t = $(this).html();
//        var that = $(this);
//        that.html('<i class="fa fa-gear fa-spin"></i> Working');
//        $.get('/Delivery/GetDeliveryExtraCharge?id=' + id, function (res) {
//            $('#ExtraChargesModal .modal-content').html(res);
//            $("#ExtraChargesModal").val(null).trigger("change");
//            $('#ExtraChargesModal').modal('show');
//            that.html(t);
//        });
//    });

//    $(document).on('click', '.edit-delivery-rule', function (e) {
//        e.preventDefault();
//        var id = $(this).attr('data-id');
//        var t = $(this).html();
//        var that = $(this);
//        that.html('<i class="fa fa-gear fa-spin"></i> Working');
//        $.get('/Delivery/GetDeliveryAccountRule?id=' + id, function (res) {
//            $('#AccountRulesModal .modal-content').html(res);
//            $("#AccountRulesModal").val(null).trigger("change");
//            $('#AccountRulesModal').modal('show');
//            that.html(t);
//        });
//    });

//    $(document).on('submit', '#DeliveryMethodForm', function (e) {
//        e.preventDefault();
//        var data = $(this).serialize();
//        $.post('/delivery/addmethod', data, function (res) {
//            tinyAlert(data.message, data.status == "ok");
//            if (res.status == "ok") {
//                window.location.href = '/delivery';
//            }
//        });
//    });
//    $(document).on('submit', '#DeliveryMethodUpdateForm', function (e) {
//        e.preventDefault();
//        var data = $(this).serialize();
//        $.post('/delivery/update', data, function (res) {
//            tinyAlert(res.message, res.status == "ok");
//        });
//    });

//    $(document).on('click', '#DeleteDeliveryMethod', function (e) {
//        e.preventDefault();
//        var id = $(this).attr('data-id');
//        if (confirm('Are you sure you want to delete this method? This cannot be undone!')) {
//            $.post('/delivery/DeleteMethod?id=' + id, {}, function (res) {
//                tinyAlert(res.message, res.status == "ok");
//                if (res.status == "ok") {
//                    window.location.href = '/delivery';
//                }
//            });
//        }
//    });

//    $(document).on('submit', '#DeliveryMethodEmailForm', function (e) {
//        e.preventDefault();
//        formDeliveryUpdate($(this));
//    });

//    $(document).on('submit', '#DeliveryMethodExtraCharge', function (e) {
//        e.preventDefault();
//        var id = $('#DeliveryMethodId').val();
//        formDeliveryUpdate($(this), function () {
//            $.get('/Delivery/GetDeliveryExtraCharges?id=' + id, function (res) {
//                $('#ExtraChargesTable').html(res);
//                $('#ExtraChargesModal').find('.close').trigger('click');
//                clearModal('#ExtraChargesModal');
//            });
//        });
//    });
//    $(document).on('submit', '#DeliveryAccountRuleForm', function (e) {
//        e.preventDefault();
//        var id = $('#AccountRuleDeliveryMethodId').val();
//        formDeliveryAccountRuleUpdate($(this), function (res) {
//            var id = res.id;
//            $.get('/Delivery/GetDeliveryAccountRules?id=' + id, function (res) {
//                $('#AccountRulesTable').html(res);
//                $('#AccountRulesTable').find('.close').trigger('click');
//                clearModal('#AccountRulesModal');
//                $('#AccountRulesModal').modal('hide');
//                $('#AccountRulesModal .close').trigger('click');
//            });
//        });
//    });
//    $(document).on('click', '.remove-delivery-rule', function (e) {
//        e.preventDefault();
//        var id = $(this).attr('data-id');
//        var deliverymethodid = $(this).attr('data-deliverymethodid');
//        var t = $(this).html();
//        var that = $(this);
//        that.html('<i class="fa fa-spinner fa-spin"></i> Working');
//        $.get('/Delivery/DeleteDeliveryAccountRule?id=' + id, function (res) {
//            $.get('/Delivery/GetDeliveryAccountRules?id=' + deliverymethodid, function (res) {
//                $('#AccountRulesTable').html(res);
//                $('#AccountRulesTable').find('.close').trigger('click');
//                clearModal('#AccountRulesModal');
//                $('#AccountRulesModal .close').trigger('click');
//            });
//        });
//    });
//}

//function formDeliveryUpdate(form, callback) {
//    var button = form.find('button[type="submit"]');
//    if (form.valid()) {
//        var buttonText = button.html();
//        button.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span> Working');
//        var data = form.serialize();
//        $.post(form.attr('action'), data,
//            function (res) {
//                tinyAlert(res.message, res.status == "ok");
//                button.html(buttonText);
//                if (callback != 'undefined' && typeof callback == "function") {
//                    callback(res);
//                }
//            });
//    }
//}

//function formDeliveryAccountRuleUpdate(form, callback) {
//    var button = form.find('button[type="submit"]');
//    if (form.valid()) {
//        var buttonText = button.html();
//        button.html('<span class="btn-label"><i class="fa fa-spinner fa-spin"></i></span> Working');
//        var data = form.serialize();
//        $.post(form.attr('action'), data,
//            function (res) {
//                tinyAlert(res.message, res.status == "ok");
//                button.html(buttonText);
//                if (callback != 'undefined' && typeof callback == "function") {
//                    callback(res);
//                }
//            });
//    }
//}

//export function clearModal(modalSelector) {
//    $(modalSelector).find('input').val('');
//    $(modalSelector).find('textarea').val('');
//    $(modalSelector).find('.uploadpreview img').remove();

//}